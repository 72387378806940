import {createStore} from 'vuex'
import axios from 'axios'
import router from "@/router";
import {getAuth, signOut} from "firebase/auth";

export default createStore({

    state: {
        authStatus: false,
        verifyStatus: false,
        user: {
            "uid": null,
            "accessToken": null,
            "email": "",
            "emailVerified": null,
            "displayName": "",
            "isAnonymous": null,
            "photoURL": "",
            "providerData": [],
            "stsTokenManager": {
                "refreshToken": "",
                "accessToken": "",
                "expirationTime": null
            },
            "createdAt": "",
            "lastLoginAt": "",
            "apiKey": "",
            "appName": ""
        }
    },

    mutations: {

        SET_AUTHENTICATED(state, value) {
            state.authStatus = value;
        },

        SET_USER(state, value) {
            state.user = value;
        },

        SET_VERIFIED(state, value) {
            state.verifyStatus = value
        }

    },

    actions: {

        async login(credentials) {
            await axios.get('/sanctum/csrf-cookie')
            await axios.post('/api/login-firebase', credentials).then((response)=>{
                return response;
            })
        },

        async logout({dispatch}) {
            const auth = getAuth();
            signOut(auth).then(() => {
                dispatch('clearUser');
                axios.get('/sanctum/csrf-cookie')
                axios.post('/api/logout').then()
                router.replace({
                    name: 'Login'
                });
            }).catch((error) => {
                console.log(error);
            });

        },

        async clearUser({commit}) {
            commit('SET_AUTHENTICATED', false)
            commit('SET_VERIFIED', false)
            commit('SET_USER', 'Guest')
        },

    },
})
