<template>
  <div class="text-center">
    <div class="inline-flex items-center mt-8">
      <div class="w-20 h-20">
        <HenIcon />
      </div>
    </div>
    <h3 class="mt-2 text-sm font-medium text-gray-900">Kandang Kosong</h3>
    <p class="mt-1 text-sm text-gray-500">
      Untuk memulai recording, silahkan masukan data pullet ke kandang
    </p>
    <div class="mt-6">
      <router-link :to="'/hen-houses/' + $route.params.id + '/pullet/create'" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Masukan Pullet
      </router-link>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from '@heroicons/vue/solid'
import HenIcon from "@/components/HenIcon";

export default {

  components: {
    PlusIcon,
    HenIcon
  },

}
</script>
