<template>
  <div>
    <!-- Page header -->
    <div class="bg-white shadow">
      <div v-if="dashboardProperties.loading" class="mt-6 px-6 pb-8">
        <div class="animate-pulse flex space-x-4">
          <div class="flex-1 space-y-4 py-1">
            <div class="h-4 bg-blue-400 rounded w-3/4"></div>
            <div class="space-y-2">
              <div class="h-4 bg-blue-400 rounded"></div>
              <div class="h-4 bg-blue-400 rounded w-5/6"></div>
            </div>
          </div>
        </div>
        <div class="animate-pulse flex space-x-4">
          <div class="flex-1 space-y-4 py-1">
            <div class="h-4 bg-blue-400 rounded w-3/4"></div>
            <div class="space-y-2">
              <div class="h-4 bg-blue-400 rounded"></div>
              <div class="h-4 bg-blue-400 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- Hen Management Tab-->
        <div v-if="henHouse.is_used">
          <div class="min-h-full">
            <Disclosure as="nav" class="bg-indigo-600" v-slot="{ open }">
              <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="flex items-center justify-between h-16">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 text-white text-xl">
                      {{henHouse.name}}
                    </div>
                    <div class="hidden md:block">
                      <div class="ml-10 flex items-baseline space-x-4">
                        <router-link v-for="tab in tabs" :key="tab.name" :to="tab.href" :class="[$route.path === tab.href ? 'bg-indigo-700 text-white' : 'text-white hover:bg-indigo-500 hover:bg-opacity-75', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="$route.path === tab.href ? 'page' : undefined">{{ tab.name }}</router-link>
                      </div>
                    </div>
                  </div>
                  <div class="-mr-2 flex md:hidden">
                    <!-- Mobile menu button -->
                    <DisclosureButton class="bg-indigo-600 inline-flex items-center justify-center p-2 rounded-md text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                      <span class="sr-only">Open main menu</span>
                      <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                      <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
                    </DisclosureButton>
                  </div>
                </div>
              </div>

              <DisclosurePanel class="md:hidden">
                <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  <DisclosureButton v-for="tab in tabs" :key="tab.name" :class="[$route.path === tab.href ? 'bg-indigo-700 text-white' : 'text-white hover:bg-indigo-500 hover:bg-opacity-75', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="$route.path === tab.href ? 'page' : undefined"><router-link :to="tab.href">{{ tab.name }}</router-link></DisclosureButton>
                </div>
              </DisclosurePanel>
            </Disclosure>

            <header class="bg-white shadow-sm">
              <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                <h1 class="text-lg leading-6 font-semibold text-gray-900" v-for="tab in tabs" :key="tab.name">{{ $route.path === tab.href ? tab.name : '' }}</h1>
              </div>
            </header>
          </div>

        </div>

        <div v-else class="mt-8">
          <EmptyPullet :capacity="henHouse['capacity']"/>
        </div>
        <router-view v-if="henHouse.pullet_active.id" :pulletId="henHouse.pullet_active.id"></router-view>
      </div>

    </div>
  </div>
</template>

<script>

import { Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'

import EmptyPullet from "@/views/pullet/EmptyPullet";
import Util from "@/util/MyUtil";

import {useRoute} from "vue-router";
import {ref} from "@vue/runtime-core";
import axios from "axios";

export default {

  components: {
    EmptyPullet,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
  },

  name: "ShowHenHouse",

  setup() {
    const route = useRoute();

    let tabs = ref([
      { name: 'Info', href: '/hen-houses/' + route.params.id },
      { name: 'Recording', href: '/hen-houses/' + route.params.id + '/recording' },
      { name: 'Stok Pakan', href: '/hen-houses/' + route.params.id + '/feed', current: route.path === '/hen-houses/' + route.params.id },
      { name: 'Penjualan', href: '/hen-houses/' + route.params.id + '/selling', current: route.path === '/hen-houses/' + route.params.id },
      { name: 'Perawatan', href: '/hen-houses/' + route.params.id + '/treatment', current: route.path === '/hen-houses/' + route.params.id },
      { name: 'Oprasional', href: '/hen-houses/' + route.params.id + '/operational', current: route.path === '/hen-houses/' + route.params.id },
      { name: 'Modal', href: '/hen-houses/' + route.params.id + '/capital', current: route.path === '/hen-houses/' + route.params.id },
      { name: 'Arus Kas', href: '/hen-houses/' + route.params.id + '/cash-flow', current: route.path === '/hen-houses/' + route.params.id },
    ]);

    let henHouse = ref({
      "id": null,
      "user_id": null,
      "name": "",
      "address": "",
      "building_at": "",
      "capacity": null,
      "period_count": null,
      "is_used": null,
      "created_at": "",
      "updated_at": "",
      "pullet_active": {
        "id": null,
        "hen_house_id": null,
        "hen_type_id": null,
        "feed_type": "",
        "is_rejected": null,
        "date_in": "",
        "supplier": "",
        "price": null,
        "start_age": null,
        "current_age": null,
        "start_population": null,
        "current_population": null,
        "mortality": null,
        "egg_masses_cumulative": null,
        "egg_productions_cumulative": null,
        "feed_intakes_cumulative": null,
        "food_conversion_ratios_cumulative": null,
        "balance": null,
        "created_at": "",
        "updated_at": "",
        "hen_type": {
          "id": null,
          "name": "",
        }
      }
    });

    let dashboardProperties = ref({
      loading : true
    });

    axios.get('/api/hen-houses/' + route.params.id).then((response)=>{
      if (response.status === 200) {
        henHouse.value = response.data.data;
        dashboardProperties.value.loading = false;
      }
    })

    return {
      henHouse,
      Util,
      tabs,
      dashboardProperties,
    }
  }

}
</script>

<style scoped>

</style>
