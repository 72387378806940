<template>
  <SlideOver :properties="properties">
    <form @submit.prevent="submitSelling" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div class="flex-1 h-0 overflow-y-auto">
        <SlideOverHead :properties="properties" @togglePanelRecording="togglePanelRecording" />
        <div class="flex-1 flex flex-col justify-between px-4">

          <div class="mt-4">
            <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Tanggal Penjualan</label>
            <div class="mt-1">
              <input type="date" :value="date" @change="$emit('update:date', $event.target.value)" required class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="mt-4">
            <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Nama Pembeli</label>
            <input :value="name" @input="$emit('update:name', $event.target.value)" type="text" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" placeholder="Ceu Onah" required>
          </div>

          <div class="flex">
            <div class="mt-4">
              <label class="block text-sm font-medium text-gray-700">Harga per Kg</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  Rp
                </div>
                <input :disabled="Number.isInteger(id)" :class="Number.isInteger(id) ? 'text-gray-400' : ''" :value="nominal_with_dots" @input="pricePerItem" required placeholder="0" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>
            <div class="mt-4 ml-1">
              <label class="block text-sm font-medium text-gray-700">Ongkos Kirim</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  Rp
                </div>
                <input :disabled="Number.isInteger(id)" :class="Number.isInteger(id) ? 'text-gray-400' : ''" :value="shipping_cost_with_dots" @input="shippingCost" required placeholder="0" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>
          </div>

          <div class="flex">
            <div class="mt-6 mr-2">
              <div>
                <label class="block text-sm font-medium text-gray-700">Jumlah Kg</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    X
                  </div>
                  <input :disabled="Number.isInteger(id)" :class="Number.isInteger(id) ? 'text-gray-400' : ''" :value="quantity" @input="totalItem" required placeholder="0" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" />
                </div>
              </div>
            </div>
            <div class="mt-6">
              <label for="account-number" class="block text-sm font-medium text-gray-700">Status Penjualan</label>
              <div class="mt-1 rounded-md shadow-sm w-32">
                <select :disabled="Number.isInteger(id)" :class="Number.isInteger(id) ? 'text-gray-500' : ''" :value="status" @change="$emit('update:status', $event.target.value)" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" required>
                  <option value="" disabled>--pilih--</option>
                  <option v-for="list in listStatus" :key="list">{{ list }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="pl-4 mt-4 text-gray-500">
            <div class="text-sm">
              Harga Jual
            </div>
            {{ MyUtil.toRupiah((nominal * quantity) + shipping_cost )  }}
          </div>

        </div>
      </div>
      <SlideOverSubmitForm :submitProperties="submitProperties" @togglePanelRecording="togglePanelRecording" />
    </form>
  </SlideOver>
</template>

<script>

import SlideOver from "@/components/SlideOver";
import SlideOverHead from "@/components/SlideOverHead";
import SlideOverSubmitForm from "@/components/SlideOverSubmitForm";
import {ref} from "@vue/runtime-core";
import MyUtil from "@/util/MyUtil";
import router from "@/router";
import axios from "axios";

export default {

  name: "CreateSelling",

  components: {
    SlideOver,
    SlideOverHead,
    SlideOverSubmitForm
  },

  props: {
    id: Number,
    properties: Object,
    date: String,
    name: String,
    nominal: Number,
    shipping_cost: Number,
    quantity: Number,
    status: String,
    nominal_with_dots: String,
    shipping_cost_with_dots: String,
    quantity_with_dots: String
  },

  setup(props, {emit}) {

    let listStatus = ref([
        'Tagihan',
        'Lunas',
    ]);

    let submitProperties = ref({
      'name' : 'Simpan',
      'loading' : false,
    });

    function pricePerItem(event) {
      let toInteger = MyUtil.stringNumberWithDotsToInteger(event.target.value)
      emit('update:nominal', toInteger)
      emit('update:nominal_with_dots', MyUtil.numberWithDots(toInteger))
    }

    function shippingCost(event) {
      let toInteger = MyUtil.stringNumberWithDotsToInteger(event.target.value)
      emit('update:shipping_cost', toInteger)
      emit('update:shipping_cost_with_dots', MyUtil.numberWithDots(toInteger))
    }

    function totalItem(event) {
      let toInteger = parseFloat(event.target.value.replace(',' , '.'));
      toInteger = isNaN(toInteger) ? null : toInteger;
      emit('update:quantity', toInteger)
    }

    let url = `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.properties.pulletId}/sellings`;
    function submitSelling() {
      submitProperties.value.loading = true;
      const method = Number.isInteger(props.id) ? axios.put : axios.post
      axios.get('/sanctum/csrf-cookie');
      method(url + (Number.isInteger(props.id) ? '/' + props.id : ''), {
        date : props.date,
        name : props.name,
        nominal : props.nominal,
        shipping_cost : props.shipping_cost,
        quantity : props.quantity,
        status : props.status,
      }).then((res) => {
        console.log(res)
        submitProperties.value.loading = false;
        togglePanelRecording();
        emit('getSelling', true);
      }).catch(err => {
        submitProperties.value.loading = false;
        emit('update:id', null)
        emit('update:date', MyUtil.dateNow())
        emit('update:name', '')
        emit('update:nominal', null)
        emit('update:nominal_with_dots', '')
        emit('update:shipping_cost', null)
        emit('update:shipping_cost_with_dots', '')
        emit('update:quantity', null)
        emit('update:quantity_with_dots', '')
        emit('update:status', '')
        console.log(err.response)
      });
    }

    function togglePanelRecording() {
      if (!submitProperties.value.loading) {
        emit('togglePanelRecording');
      }
    }

    return {
      togglePanelRecording,
      MyUtil,
      pricePerItem,
      shippingCost,
      totalItem,
      listStatus,
      submitSelling,
      submitProperties
    }

  }

}

</script>
