function numberWithDots(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function toRupiah(nominal) {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 }).format(nominal);
}

function dateNow() {
    const date = new Date();
    return  date.toISOString().slice(0,10);
}

function toLocaleDateString(date) {
    return (new Date(date)).toLocaleDateString('id-ID', {
        weekday : 'long',
        year    : 'numeric',
        month   : 'short',
        day     : 'numeric'
    });
}

function dayToWeek(day) {
    return Math.floor(day / 7)
}

function toFixedIfNecessary( value, dp = 2 ){
    return +parseFloat(value).toFixed( dp );
}

function todayFormFormat() {
    const today = new Date();
    let dd      = today.getDate();
    let mm    = today.getMonth()+1;
    const yyyy  = today.getFullYear();

    if(dd < 10) dd='0'+dd;
    if(mm < 10) mm='0'+mm;

    return  yyyy+'-'+mm+'-'+dd;
}

function stringNumberWithDotsToInteger(string) {
    return (string === '') ? 0 : parseInt(string.toString().replaceAll('.', ''))
}

export default { numberWithDots, toRupiah, dateNow, toLocaleDateString, dayToWeek, toFixedIfNecessary, todayFormFormat, stringNumberWithDotsToInteger }
