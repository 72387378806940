<template>
  <TransitionRoot as="template" :show="properties.show" class="z-40">
      <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="togglePanelRecording" :open="properties.show">
        <div class="absolute inset-0 overflow-hidden">
          <DialogOverlay class="absolute inset-0" />
          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <div class="w-screen max-w-md">
                <slot></slot>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
</template>

<script>

import { ref } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {

  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },

  props: [
      'properties'
  ],

  setup( props, {emit} ) {
    const open = ref(true)
    function togglePanelRecording() {
      emit('togglePanelRecording');
    }

    return {
      open,
      togglePanelRecording
    }
  },
}
</script>
