<template>
  <div class="py-2 md:px-8 px-3 bg-white pb-6">

    <CreateCapital
        :capitalPanelProperties="capitalPanelProperties"
        :submit-properties="submitProperties"
        @togglePanelRecording="togglePanelCapital"
        @submitCapital="submitCapital"
        v-model:userId="capitalData.user_id"
        v-model:ownersCapitals="ownersCapitals"
        v-model:date="capitalData.date"
        v-model:nominal="capitalData.nominal"
        v-model:nominalWithDots="capitalData.nominalWithDots"
        v-model:transactionType="capitalData.transaction_type"
        v-model:description="capitalData.description"
    />

    <LoadingElipsis v-if="capitalProperties.loadCapital"/>
    <div v-else>
      <div v-if="capitals.length === 0">
        <EmptyProject @click="togglePanelCapital" head-title="Catatan Modal Masih kosong" description="Ayo buat catatan modal pertama kamu" btn-title="Catat Modal"/>
      </div>
      <div v-else>
        <div class="flex justify-end">
          <button @click="togglePanelCapital" type="button" class="mb-6 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
            Catat Modal
          </button>
        </div>

        <div>
          Total Modal : {{MyUtil.toRupiah(capitalProperties.totalNominalCapital)}}
        </div>
        <table class="text-sm text-gray-500 mb-4">
          <tr>
            <td class="px-2 font-bold">Pemodal</td>
            <td class="px-2 font-bold">%</td>
            <td class="px-2 font-bold">Deposit</td>
            <td class="px-2 font-bold">Selisih</td>
          </tr>
          <tr v-for="(ownersCapital, index) in ownersCapitals" :key="index">
            <td class="px-2">{{ ownersCapital['user']['name'] }}</td>
            <td class="px-2">{{ ownersCapital['percentage'] }}%</td>
            <td class="px-2">{{ MyUtil.toRupiah(ownersCapital['deposit_sum_nominal']) }}</td>
            <td class="px-2">{{ MyUtil.toRupiah(ownersCapital['deposit_sum_nominal'] - (capitalProperties.totalNominalCapital * (ownersCapital['percentage'] / 100))) }}</td>
          </tr>
        </table>

        <!-- Activity list (smallest breakpoint only) -->
        <div class="shadow sm:hidden">
          <ul class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
            <li v-for="(capitalData) in capitals" :key="capitalData['id']">
              <a href="#" class="block px-4 py-4 bg-white hover:bg-gray-50">
                <span class="flex items-center space-x-4">
                  <span class="flex-1 flex space-x-2 truncate">
                    <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span class="flex flex-col text-gray-500 text-sm truncate">
                      <span class="truncate">{{ capitalData['user']['name'] }}</span>
                      <span class="items-center flex">
                        <span class="text-gray-900 font-medium">{{ MyUtil.toRupiah(capitalData['nominal']) }}</span>
                        <span :class="[statusStyles[capitalData['transaction_type']], 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ml-2']" >
                          {{ capitalData['transaction_type'] }}
                        </span>
                      </span>
                      <span class="truncate">{{ capitalData['description'] }}</span>
                      <time :datetime="capitalData['date']">{{ MyUtil.toLocaleDateString(capitalData['date']) }}</time>
                    </span>
                  </span>
                  <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </a>
            </li>
          </ul>
        </div>

        <!-- Activity table (small breakpoint and up) -->
        <div class="hidden sm:block">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col mt-2">
              <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                  <tr>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Transaction
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                      Status
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="transaction in transactions" :key="transaction.id" class="bg-white">
                    <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <div class="flex">
                        <a :href="transaction.href" class="group inline-flex space-x-2 truncate text-sm">
                          <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          <p class="text-gray-500 truncate group-hover:text-gray-900">
                            {{ transaction.name }}
                          </p>
                        </a>
                      </div>
                    </td>
                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                      <span class="text-gray-900 font-medium">{{ transaction.amount }} </span>
                      {{ transaction.currency }}
                    </td>
                    <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                      <span :class="[statusStyles[transaction.status], 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                        {{ transaction.status }}
                      </span>
                    </td>
                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                      <time :datetime="transaction.datetime">{{ transaction.date }}</time>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!-- Pagination -->
                <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                  <div class="hidden sm:block">
                    <p class="text-sm text-gray-700">
                      Showing
                      {{ ' ' }}
                      <span class="font-medium">1</span>
                      {{ ' ' }}
                      to
                      {{ ' ' }}
                      <span class="font-medium">10</span>
                      {{ ' ' }}
                      of
                      {{ ' ' }}
                      <span class="font-medium">20</span>
                      {{ ' ' }}
                      results
                    </p>
                  </div>
                  <div class="flex-1 flex justify-between sm:justify-end">
                    <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      Previous
                    </a>
                    <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      Next
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="trigger"/>
  </div>
</template>

<script>

import {CashIcon, ChevronRightIcon} from "@heroicons/vue/outline";
import {onMounted, ref} from "@vue/runtime-core";
import MyUtil from "@/util/MyUtil";
import axios from "axios";
import router from "@/router";
import LoadingElipsis from "@/components/LoadingElipsis";
import EmptyProject from "@/components/EmptyProject";
import CreateCapital from "@/components/CreateCapital";

const transactions = [
  {
    id: 1,
    name: 'Payment to Molly Sanders',
    href: '#',
    amount: '$20,000',
    currency: 'USD',
    status: 'success',
    date: 'July 11, 2020',
    datetime: '2020-07-11',
  },
  // More transactions...
]

const statusStyles = {
  deposit: 'bg-green-100 text-green-800',
  'take profit': 'bg-yellow-100 text-yellow-800',
  withdraw: 'bg-red-100 text-red-800',
}

export default {

  name: "Capital",

  components: {
    EmptyProject,
    LoadingElipsis,
    CashIcon,
    ChevronRightIcon,
    CreateCapital
  },

  props: {
    pulletId: Number
  },

  setup(props) {

    const capitalProperties = ref({
      showPanelCapital: false,
      capitalUrl: `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/capitals`,
      ownersCapitalUrl: `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/owners-capitals`,
      totalNominalCapital: null,
      loadCapital: true,
      setObserver: setObserver,
      observer: true,
    });

    const capitalPanelProperties = ref({
      show: false
    });

    const submitProperties = ref({
      name: 'Simpan',
      loading: false
    });

    const capitalData= ref({
      user_id: null,
      date: MyUtil.dateNow(),
      nominal: null,
      nominalWithDots: '',
      transaction_type: '',
      description: '',
    });


    let capitals = ref([]);

    let ownersCapitals = ref([]);

    let trigger = ref();

    function getCapitalData() {

      axios.get(capitalProperties.value.capitalUrl).then((response)=>{

        // Convert data from object to array
        let toArray = Object.keys(response.data.data.capitals.data).map((key) => response.data.data.capitals.data[key])

        // Push data to array collection
        toArray.forEach((res)=> {
          capitals.value.push(res);
        })

        // set next_page_url for infinite scroll
        capitalProperties.value.capitalUrl = response.data.data.capitals.next_page_url ?? `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/capitals`

        if (response.data.data.capitals.next_page_url === null) {
          capitalProperties.value.observer = false;
        }

        ownersCapitals.value = response.data.data['owners_capitals'];
        capitalProperties.value.totalNominalCapital = response.data.data['total_deposit'];
        capitalProperties.value.loadCapital = false

      })
    }

    let setObserver = onMounted(()=> {
      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (capitalProperties.value.observer) {
              getCapitalData()
            }
          }
        })
      }, {
        root: null,
        threshold: 0
      });
      observer.observe(trigger.value);
    })

    function togglePanelCapital() {
      capitalPanelProperties.value.show = !capitalPanelProperties.value.show;
      if (!capitalPanelProperties.value.show) {
        capitalData.value.user_id = null;
        capitalData.value.date = MyUtil.dateNow();
        capitalData.value.nominal = null;
        capitalData.value.nominalWithDots = '';
        capitalData.value.transaction_type = '';
        capitalData.value.description = '';
      }
    }

    function submitCapital() {
      submitProperties.value.loading = true;
      axios.get('/sanctum/csrf-cookie');
      axios.post(capitalProperties.value.capitalUrl, capitalData.value).then(()=>{
        togglePanelCapital();
      }).then(()=>{
        getCapitalData();
        submitProperties.value.loading = false;
      }).catch((err)=>{
        console.log(err.response)
        submitProperties.value.loading = false;
      });
    }

    return {
      transactions,
      statusStyles,
      capitalProperties,
      togglePanelCapital,
      capitalPanelProperties,
      capitalData,
      submitCapital,
      ownersCapitals,
      capitals,
      MyUtil,
      submitProperties,
      trigger
    }

  }

}
</script>

<style scoped>

</style>
