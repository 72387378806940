<template>
  <SlideOver :properties="capitalPanelProperties">
    <form @submit.prevent="submitCapital" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div class="flex-1 h-0 overflow-y-auto">
        <SlideOverHead @togglePanelRecording="togglePanelRecording" :properties="properties"/>
        <div class="flex-1 flex flex-col justify-between px-4">
          <div class="mt-4">
            <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Tanggal</label>
            <div class="mt-1">
              <input :value="date" @change="$emit('update:date', $event.target.value)" type="date" required class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="mt-4">
            <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Pilih pemodal</label>
            <select @change="$emit('update:userId', parseInt($event.target.value))" required class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
              <option value="" disabled selected>--pilih pemodal--</option>
              <option v-for="(ownersCapital, index) in ownersCapitals" :key="index" :value="ownersCapital.user.id">
                {{ ownersCapital.user.name }}
              </option>
            </select>
          </div>
          <div class="flex">
            <div class="mt-4">
              <label class="block text-sm font-medium text-gray-700">Nominal</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  Rp
                </div>
                <input :value="nominalWithDots" @input="convertNominal" required type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" placeholder="0" />
              </div>
            </div>
            <div class="mt-4 ml-1 w-1/2">
              <label class="block text-sm font-medium text-gray-700">Jenis transaksi</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <select @change="$emit('update:transactionType', $event.target.value)" required class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                  <option value="" disabled selected>-- pilih --</option>
                  <option v-for="(capitalTransaction, index) in capitalTransactions" :key="index" :value="capitalTransaction.value">
                    {{ capitalTransaction.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <label class="block text-sm font-medium text-gray-700">Keterangan</label>
            <textarea required :value="description" @input="$emit('update:description', $event.target.value)" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>
        </div>
      </div>
      <SlideOverSubmitForm @togglePanelRecording="togglePanelRecording" :submit-properties="submitProperties" />
    </form>
  </SlideOver>
</template>

<script>

import SlideOver from "@/components/SlideOver";
import SlideOverHead from "@/components/SlideOverHead";
import SlideOverSubmitForm from "@/components/SlideOverSubmitForm";
import {ref} from "@vue/runtime-core";
import MyUtil from "@/util/MyUtil";

export default {

  name: "CreateCapital",

  props: {
    capitalPanelProperties: Object,
    submitProperties: Object,
    ownersCapitals: Array,
    userId: Number,
    date: String,
    nominal: Number,
    nominalWithDots: String,
    transactionType: String,
    description: String,
  },

  components: {
    SlideOver,
    SlideOverHead,
    SlideOverSubmitForm
  },

  setup(props, {emit}) {

    const properties = ref({
      title: 'Catat Modal'
    });

    const capitalTransactions = ref([
      {
        value: 'deposit',
        name: 'Deposit',
      },
      {
        value: 'take profit',
        name: 'Take Profit',
      },
      {
        value: 'withdraw',
        name: 'Withdraw',
      },
    ]);

    function togglePanelRecording() {
      emit('togglePanelRecording')
    }

    function submitCapital() {
      emit('submitCapital')
    }

    function convertNominal(event) {
      console.log('masuk')
      let toInteger = MyUtil.stringNumberWithDotsToInteger(event.target.value)
      emit('update:nominal', toInteger)
      emit('update:nominalWithDots', MyUtil.numberWithDots(toInteger))
    }

    return {
      submitCapital,
      togglePanelRecording,
      convertNominal,
      properties,
      capitalTransactions,
    }
  }

}
</script>
