<template>
  <SlideOver :properties="properties">
    <form @submit.prevent="submitStock" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div class="flex-1 h-0 overflow-y-auto">
        <SlideOverHead :properties="properties" @togglePanelRecording="closePanel" />
        <div class="flex-1 flex flex-col justify-between px-3">
          <div class="mt-4">
            <label for="date" class="block text-sm font-medium text-gray-700">Tanggal Beli</label>
            <div class="mt-1">
              <input :value="date" @change="$emit('update:date', $event.target.value)" type="date" required class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="mt-6">
            <label class="block text-sm font-medium text-gray-700">Jenis Pakan</label>
            <select v-if="properties.feedTypes" :disabled="Number.isInteger(id)" :class="Number.isInteger(id) ? 'text-gray-300' : ''" :value="feed_type" @change="$emit('update:feed_type', $event.target.value)" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" required>
              <option value="" disabled>Pilih Jenis Pakan</option>
              <option v-for="feedType in properties.feedTypes" :key="feedType">{{ feedType }}</option>
            </select>
          </div>
          <div class="mt-6">
            <label for="name" class="block text-sm font-medium text-gray-700">Supplier</label>
            <div class="mt-1">
              <input :value="supplier" @input="$emit('update:supplier', $event.target.value)" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Petani / PT. Pakan" />
            </div>
          </div>
          <div class="flex">
            <div class="mt-6 mr-2">
              <div>
                <label class="block text-sm font-medium text-gray-700">Harga Per Kg</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    Rp
                  </div>
                  <input :value="price_per_kg_with_dots" @input="pricePerKg" placeholder="0" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" />
                </div>
              </div>
            </div>
            <div class="mt-6">
              <label for="account-number" class="block text-sm font-medium text-gray-700">Total Berat</label>
              <div class="mt-1 relative rounded-md shadow-sm w-32">
                <input :value="mass_with_dots" @input="totalMass" type="text" placeholder="0" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md" />
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  Kg
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-between mt-3">
            <div class="">
              <div class="text-gray-500 text-sm mt-2 ml-2">
                Total {{ MyUtil.toRupiah(price_per_kg * mass) }}
              </div>
            </div>
            <div class="" v-if="Number.isInteger(id)">
              <label for="account-number" class="block text-sm font-medium text-gray-700">Sisa Berat</label>
              <div class="mt-1 relative rounded-md shadow-sm w-32">
                <input :disabled="status === 'in_stock'" :class="status === 'in_stock' ? 'text-gray-300' : ''" :value="mass_in_stock_with_dots" @input="massInStock" type="text" placeholder="0" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md" />
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  Kg
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end">
            <span class="text-sm text-red-400" v-if="formErrors.mass_in_stock">{{ formErrors.mass_in_stock }}</span>
          </div>
          <div class="flex items-center">
            <div class="mt-6 w-1/2">
              <label for="name" class="block text-sm font-medium text-gray-700">Biaya Angkut</label>
              <div class="mt-1 relative">
                <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  Rp
                </div>
                <input :value="shipping_cost_with_dots" @input="shippingCost" required type="text" class="pl-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="0" />
              </div>
            </div>
            <div class="mt-12 ml-3 text-gray-500 text-sm">
              {{MyUtil.toRupiah(shipping_cost / mass)}} / Kg
            </div>
          </div>
          <div class="text-gray-500 mt-4 ml-2">
            Total {{ MyUtil.toRupiah((price_per_kg * mass) + shipping_cost) }}
          </div>
        </div>
      </div>
      <SlideOverSubmitForm :submitProperties="submitProperties" @togglePanelRecording="closePanel" />
    </form>
  </SlideOver>
</template>


<script>

import SlideOver from "@/components/SlideOver";
import {ref} from "@vue/runtime-core";
import MyUtil from "@/util/MyUtil";
import SlideOverSubmitForm from "@/components/SlideOverSubmitForm";
import SlideOverHead from "@/components/SlideOverHead";
import axios from "axios";

export default {

  name: "CreateStock",

  props: {
    properties: Object,
    id: Number,
    supplier: String,
    date: String,
    feed_type: String,
    price_per_kg: Number,
    price_per_kg_with_dots: String,
    mass: Number,
    mass_with_dots: String,
    shipping_cost: Number,
    shipping_cost_with_dots: String,
    status: String,
    mass_in_stock: Number,
    mass_in_stock_with_dots: String
  },

  components: {
    SlideOver,
    SlideOverSubmitForm,
    SlideOverHead
  },

  setup(props, {emit}) {

    let submitProperties = ref({
      'name' : 'Simpan',
      'loading' : false
    });

    let formErrors = ref({
      mass_in_stock : ''
    })

    async function pricePerKg(event) {
      let toInteger = MyUtil.stringNumberWithDotsToInteger(event.target.value)
      emit('update:price_per_kg', toInteger)
      emit('update:price_per_kg_with_dots', MyUtil.numberWithDots(toInteger))
    }

    function massInStock(event) {
      let toInteger = MyUtil.stringNumberWithDotsToInteger(event.target.value)
      if (toInteger <= props.mass) {
        formErrors.value.mass_in_stock = ""
        emit('update:mass_in_stock', toInteger)
        emit('update:mass_in_stock_with_dots', MyUtil.numberWithDots(toInteger))
      } else {
        formErrors.value.mass_in_stock = "Tidak boleh lebih besar dari total berat"
        emit('update:mass_in_stock', 0)
        emit('update:mass_in_stock_with_dots', MyUtil.numberWithDots(0))
      }
    }

    async function totalMass(event) {
      let toInteger = MyUtil.stringNumberWithDotsToInteger(event.target.value)
      emit('update:mass', toInteger)
      emit('update:mass_with_dots', MyUtil.numberWithDots(toInteger))
      if (props.status === 'in_stock') {
        massInStock(event)
      }
    }

    async function shippingCost(event) {
      let toInteger = MyUtil.stringNumberWithDotsToInteger(event.target.value)
      emit('update:shipping_cost', toInteger)
      emit('update:shipping_cost_with_dots', MyUtil.numberWithDots(toInteger))
    }

    function closePanel() {
      if (!submitProperties.value.loading) {
        emit('togglePanelRecording');
      }
    }

    function submitStock() {
      submitProperties.value.loading = true;
      console.log(props.id)
      const method = Number.isInteger(props.id) ? axios.put : axios.post
      axios.get('/sanctum/csrf-cookie');
      method(props.properties.url, {
        supplier : props.supplier,
        date : props.date,
        feed_type : props.feed_type,
        status : props.status,
        price_per_kg : props.price_per_kg,
        mass : props.mass,
        mass_in_stock : props.mass_in_stock,
        shipping_cost : props.shipping_cost,
    }).then((res) => {
        console.log(res)
        submitProperties.value.loading = false;
      }).then(()=>{
        closePanel();
        emit('getFeedStocks');
      }) .catch(err => {
        submitProperties.value.loading = false;
        console.log(err.response)
      });

    }

    return {
      MyUtil,
      submitProperties,
      formErrors,
      pricePerKg,
      totalMass,
      shippingCost,
      submitStock,
      closePanel,
      massInStock,
    }

  }

}

</script>
