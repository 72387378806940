<template>
  <div>
    <a href="#" class="block px-4 py-4 bg-white hover:bg-gray-50" @click="showAction">
      <slot></slot>
    </a>
    <TransitionRoot :show="action.show">
      <TransitionChild
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enter-from="-translate-y-full opacity-0"
          enter-to="-translate-y-0 opacity-100"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leave-from="-translate-y-0 opacity-100"
          leave-to="-translate-y-full opacity-0"
          class="border-t border-gray-200 flex justify-end text-sm py-2 px-4"
      >
      <SubmitButton
          v-if="actionDelete"
          @click="deleteButton(itemId)"
          :is-loading="loadingDelete"
          name="Delete"
          class="inline-block px-3 py-1 bg-red-500 text-white rounded"
      />
      <submit-button
        v-if="actionEdit"
        name="Edit"
        :is-loading="action.loading"
        class="inline-block ml-2 px-3 py-1 bg-indigo-500 text-white rounded"
        type="button"
        @click="editButton(itemId)"
      />
      <submit-button name="Bayar"
         v-if="actionPay"
         @click="togglePanelBill"
         :is-loading="payLoading && (payLoadingId === itemId)"
         class=" ml-3 cursor-pointer bg-green-500 text-green-50 px-3 py-1 rounded items-center"
      />

      </TransitionChild>
    </TransitionRoot>
  </div>
</template>

<script>

import {TransitionRoot, TransitionChild} from "@headlessui/vue";
import {ref} from "@vue/runtime-core";
import SubmitButton from "@/components/SubmitButton";
import axios from "axios";

export default {

  name: "Smallitem",

  components: {
    SubmitButton,
    TransitionChild,
    TransitionRoot
  },

  props: {
    index: Number,
    itemId: Number,
    properties: Object,
    payLoading: Boolean,
    payLoadingId: Number,
    actionEdit: Boolean,
    actionPay: Boolean,
    actionDelete: Boolean,
    loadingDelete: Boolean,
  },

  setup(props, {emit}) {

    let action = ref({
      show: false,
      loading: false,
    });

    let feedData = ref({});

    function showAction() {
      action.value.show = !action.value.show;
    }

    function togglePanelBill() {
      emit('update:payLoading', true);
      emit('togglePanelBill');
    }

    async function editButton(itemId) {
      action.value.loading = true
      await axios.get(props.properties.url + '/' + itemId)
        .then(response => {
          feedData.value = response.data.data
        }).then(()=>{
          emit('togglePanelRecording', feedData.value)
        })
        .then(()=>{
          action.value.loading = false
        }).catch((e)=>{
          action.value.loading = false
          console.log(e.response)
        })
    }

    function deleteButton(itemId) {
      emit('toggleModalDelete');
      emit('update:deleteId', itemId);
      emit('update:loadingDelete', true);
    }

    return {
      action,
      showAction,
      editButton,
      togglePanelBill,
      deleteButton
    }

  }

}
</script>
