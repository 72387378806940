<template>
  <Modal :show="showDelete">
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div class="mt-3 sm:mt-0 sm:ml-4 px-3">
          <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">
            Yakin hapus operasional {{ operationalData.name }}?
          </DialogTitle>
          <div class="mt-2">
            <table class="text-gray-500">
              <tr>
                <td class="px-2">Tanggal</td>
                <td class="px-2">{{ MyUtil.toLocaleDateString(operationalData.date) }}</td>
              </tr>
              <tr>
                <td class="px-2">Nominal</td>
                <td class="px-2">{{ MyUtil.toRupiah(operationalData.nominal) }}</td>
              </tr>
              <tr>
                <td class="px-2">Quantity</td>
                <td class="px-2">{{ MyUtil.numberWithDots(operationalData.quantity) }}</td>
              </tr>
              <tr>
                <td class="px-2">Total </td>
                <td class="px-2">{{ MyUtil.toRupiah(operationalData.nominal * operationalData.quantity) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      <form @submit.prevent="">
        <SubmitButton
            name="Yakin Hapus !"
            :is-loading="properties.deleteLoading"
            @click="deleteSelling"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        />
        <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="$emit('update:showDelete', false)" ref="cancelButtonRef">
          Batal
        </button>
      </form>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import SubmitButton from "@/components/SubmitButton";
import {ExclamationIcon} from "@heroicons/vue/outline";
import {DialogTitle} from "@headlessui/vue";
import {ref} from "@vue/runtime-core";
import MyUtil from "@/util/MyUtil";
import axios from "axios";

export default {

  name: "ModalDeleteOperational",

  components: {
    Modal,
    SubmitButton,
    ExclamationIcon,
    DialogTitle
  },

  props: {
    showDelete: Boolean,
    deleteUrl: String,
    operationalData: Object
  },

  setup(props, {emit}) {

    let properties = ref({
      deleteLoading: false,
    });

    function deleteSelling() {
      properties.value.deleteLoading = true;
      axios.delete(props.deleteUrl).then(()=>{
        emit('getOperationals', true);
        properties.value.deleteLoading = false
        emit('update:showDelete', false);
      }).catch(()=>{
        properties.value.deleteLoading = false
      })
    }

    return {
      properties,
      MyUtil,
      deleteSelling
    }

  }

}
</script>

<style scoped>

</style>
