import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './index.css'
import auth from "@/store/auth";
import './firebase'
import {getAuth, onIdTokenChanged} from "firebase/auth";

const firebaseAuth = getAuth();
let app;
let waiting = false;
axios.defaults.withCredentials = true
axios.defaults.baseURL = (process.env.VUE_APP_LOCAL) ? 'http://localhost:8000' : 'https://api.bertelur.com';
axios.interceptors.response.use(function (res) {
    return res;
}, function (error) {

    // error response
    if (error.response.status === 401) {
        if (window.location.pathname !== 'login' ) {
            getAuth().currentUser.getIdToken(true).then((token)=>{
                axios.defaults.headers.common['accessToken'] = token;
                axios.defaults.headers.common['email'] = auth.state.user?.email;
                auth.commit('SET_USER', getAuth()?.currentUser)
            }).then(()=>{
                router.replace({
                    name: 'Dashboard'
                }).then()
            }).catch(()=>{
                auth.dispatch('clearUser').then(()=>{
                    router.replace({
                        name: 'Login'
                    }).then();
                })
            })
        }
    }

    return Promise.reject(error);

});

onIdTokenChanged(firebaseAuth, user => {
    if (user) {
        if (!waiting) {
            waiting = true;
            // eslint-disable-next-line no-inner-declarations
            async function getToken() {
                await user.getIdToken(true).then((token)=>{
                    console.log('access token')
                    axios.defaults.headers.common['accessToken'] = token;
                    axios.defaults.headers.common['email'] = auth.state.user?.email;
                })
            }

            getToken().then(()=>{
                if (!auth.state.user?.accessToken) {
                    console.log('user is not logged in, then login')
                    axios.get('/sanctum/csrf-cookie').then(()=>{
                        axios.post('/api/login-firebase', user).then((response)=>{
                            user.displayName == null ? user.displayName = response.data.data.name : '';
                            user.photoURL == null ? user.photoURL = 'https://tailwindui.com/img/logos/workflow-mark-purple-600-to-indigo-600.svg' : '';
                            auth.commit('SET_USER', user)
                            auth.commit('SET_AUTHENTICATED', true)
                            auth.commit('SET_VERIFIED', true)
                        }).then(()=>{
                            document.getElementById('load-script').remove();
                            if (!app) {
                                app = createApp(App)
                                    .use(store)
                                    .use(router)
                                    .mount('#app')
                            }
                        }).then(()=>{
                            waiting = false;
                        }).then(()=>{
                            if(router.currentRoute.value.name === 'Login') {
                                router.replace({
                                    name: 'Dashboard'
                                }).then()
                            }
                        }).catch((error)=>{
                            console.log(error.response)
                        })
                    })
                }
            })
        }

    } else {
        auth.dispatch('clearUser').then(()=>{
            if (!app) {
                document.getElementById('load-script').remove();
                app = createApp(App)
                    .use(store)
                    .use(router)
                    .mount('#app')
            }
        }).then(()=>{
            router.replace({
                name: 'Login'
            }).then();
        })
    }
})

