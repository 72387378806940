<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1168.000000 1280.000000"
         preserveAspectRatio="xMidYMid meet">
      <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
      </metadata>
      <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
         fill="#000000" stroke="none">
        <path d="M1734 12770 c-131 -65 -251 -196 -304 -329 -81 -206 -62 -493 46
-678 13 -23 24 -46 24 -51 0 -5 -21 -17 -47 -27 -86 -32 -243 -114 -336 -177
-124 -82 -296 -256 -361 -366 -50 -84 -116 -256 -116 -304 0 -18 -11 -37 -32
-57 -58 -51 -545 -467 -579 -494 -18 -14 -30 -29 -27 -33 6 -6 152 -37 603
-129 99 -20 183 -40 188 -44 4 -4 -16 -43 -46 -87 -61 -91 -148 -279 -174
-374 -25 -92 -25 -377 0 -470 79 -291 296 -422 495 -296 108 68 216 233 282
431 l33 99 29 -34 c196 -234 338 -583 378 -930 15 -128 15 -543 0 -660 -52
-414 -68 -545 -80 -655 -21 -197 -31 -264 -51 -369 -33 -166 -14 -225 75 -244
29 -6 40 -13 36 -23 -20 -56 -2 -605 20 -634 4 -5 10 -30 13 -55 36 -296 207
-739 397 -1028 39 -59 68 -113 65 -119 -2 -7 1 -13 8 -13 17 0 60 -57 52 -69
-3 -5 3 -12 12 -16 10 -3 34 -26 53 -52 106 -139 323 -363 480 -492 151 -126
423 -302 590 -383 267 -129 282 -136 446 -189 l62 -19 5 -133 c2 -76 11 -157
21 -190 55 -197 203 -369 367 -427 l54 -18 3 -796 2 -796 -28 0 c-45 0 -275
-47 -370 -75 -148 -45 -434 -146 -483 -170 -86 -44 -139 -103 -139 -155 0 -14
11 -25 33 -35 45 -18 82 -9 413 99 397 130 482 148 693 147 119 -1 251 -16
251 -30 0 -3 -28 -22 -62 -41 -66 -38 -118 -98 -118 -137 0 -18 9 -28 33 -38
45 -18 89 -8 402 95 362 119 456 140 650 147 201 7 273 -4 384 -57 123 -59
201 -54 233 16 17 38 -5 66 -89 114 -124 71 -286 120 -398 120 -33 0 -45 4
-48 18 -3 9 -4 358 -3 776 l1 758 60 22 c78 28 135 68 213 149 69 72 129 184
159 297 20 76 24 282 6 343 -11 35 -10 39 12 53 13 9 49 22 80 30 31 8 84 28
118 43 34 15 98 43 142 60 44 18 94 39 110 48 217 114 270 143 332 182 40 25
91 57 115 71 235 138 656 539 863 820 30 41 81 107 130 166 14 18 25 38 25 47
0 8 7 21 16 28 21 17 103 138 173 254 30 50 62 99 71 110 17 20 49 76 97 165
13 25 34 63 46 85 86 149 230 428 252 489 10 26 27 53 37 59 10 7 18 22 18 34
0 12 20 64 45 115 25 51 45 98 45 104 0 6 10 23 21 38 12 15 19 29 16 32 -2 3
15 55 40 117 25 62 53 137 63 167 11 30 28 80 40 110 25 64 71 223 106 365 19
78 24 126 24 216 0 133 15 186 117 418 81 184 154 300 254 405 148 155 258
205 449 206 213 0 316 -34 726 -240 366 -184 398 -195 569 -195 115 0 135 3
171 22 60 32 100 84 124 159 98 318 -182 724 -680 988 -235 124 -508 195 -708
183 -344 -20 -753 -295 -917 -617 -37 -73 -111 -297 -138 -419 -14 -62 -28
-115 -30 -118 -11 -11 -22 210 -21 427 1 221 2 241 27 335 38 143 87 235 172
321 83 85 188 140 341 181 98 25 106 26 564 32 l465 7 80 27 c191 66 263 155
253 312 -6 98 -46 181 -129 269 -209 224 -537 338 -969 339 -477 1 -784 -141
-999 -463 -145 -217 -212 -423 -212 -655 -1 -239 42 -451 135 -673 31 -72 67
-175 81 -228 23 -89 60 -333 51 -341 -2 -2 -27 40 -56 94 -29 54 -120 210
-203 348 -312 518 -400 732 -401 980 0 89 4 124 23 183 60 186 227 350 486
480 44 22 177 81 295 132 303 130 395 183 487 283 134 145 134 296 -1 431 -69
70 -147 112 -271 147 -70 20 -103 23 -260 24 -200 0 -237 -6 -455 -77 -309
-100 -589 -278 -734 -467 -49 -64 -115 -189 -140 -266 -48 -150 -59 -227 -59
-400 -1 -254 36 -392 157 -596 123 -207 181 -278 394 -482 187 -180 336 -425
480 -787 88 -221 192 -570 192 -645 0 -33 13 -25 -215 -130 -88 -40 -356 -125
-395 -125 -7 0 -8 17 -4 50 4 28 3 50 -1 50 -4 0 -20 -11 -37 -25 -224 -188
-1111 99 -2283 740 -430 235 -688 355 -929 432 l-90 28 -65 83 c-328 416 -571
859 -847 1542 -267 664 -546 1085 -902 1362 l-109 84 58 62 c147 156 217 314
207 463 -12 167 -127 277 -308 296 -75 7 -185 -17 -279 -62 -40 -19 -77 -33
-82 -29 -5 3 -9 18 -9 33 0 38 -38 92 -80 114 -81 42 -244 -15 -339 -119 -29
-31 -54 -55 -56 -52 -3 2 -25 65 -50 139 -54 157 -102 259 -145 312 -69 82
-163 99 -266 47z m3402 -9765 c52 -159 169 -301 303 -368 41 -20 82 -37 92
-37 18 0 19 -25 19 -783 l0 -782 -122 -24 c-67 -13 -177 -41 -244 -62 -67 -21
-128 -39 -137 -39 -8 0 -51 18 -94 40 -48 25 -126 52 -194 69 l-114 28 -3 790
-2 790 57 21 c195 71 354 275 389 499 l8 48 13 -70 c7 -38 20 -92 29 -120z"/>
        <path d="M9680 3934 c-91 -20 -215 -62 -283 -96 -744 -372 -1325 -1459 -1257
-2354 47 -616 352 -1090 854 -1329 174 -83 302 -120 496 -144 433 -54 880 91
1191 387 201 191 341 414 434 695 132 395 151 850 55 1327 -164 818 -596 1404
-1115 1511 -108 22 -279 24 -375 3z"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "HenIcon"
}
</script>
