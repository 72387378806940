<template>
  <CreateTreatment
    :properties="properties"
    :pulletId="pulletId ? pulletId : null"
    @togglePanelRecording="togglePanelRecording"
    @getTreatmentCost="getTreatmentCost"
  />

  <div class="py-2 md:px-8 px-3 bg-white">

    <div class="flex justify-end">
      <button @click="togglePanelRecording" type="button" class="mb-6 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
        Catat Pembelian
      </button>
    </div>

    <!-- Activity list (smallest breakpoint only) -->
    <div class="shadow sm:hidden">
      <ul class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
        <li v-for="transaction in treatmentCosts" :key="transaction['id']">
          <a href="#" class="block px-4 py-4 bg-white hover:bg-gray-50">
            <span class="flex items-center space-x-4">
              <span class="flex-1 flex space-x-2 truncate">
                <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span class="flex flex-col text-gray-500 text-sm truncate">
                  <span class="truncate">{{ transaction.name }} <span class="text-green-500"> X {{ transaction['quantity'] }}</span></span>
                  <time :datetime="transaction['date']">{{ MyUtil.toLocaleDateString(transaction['date']) }}</time>
                </span>
              </span>
              <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </a>
        </li>
      </ul>
    </div>

    <!-- Activity table (small breakpoint and up) -->
    <div class="hidden sm:block">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col mt-2">
          <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
              <tr>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Transaction
                </th>
                <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                  Status
                </th>
                <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="transaction in transactions" :key="transaction.id" class="bg-white">
                <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div class="flex">
                    <a :href="transaction.href" class="group inline-flex space-x-2 truncate text-sm">
                      <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                      <p class="text-gray-500 truncate group-hover:text-gray-900">
                        {{ transaction.name }}
                      </p>
                    </a>
                  </div>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <span class="text-gray-900 font-medium">{{ transaction.amount }} </span>
                  {{ transaction.currency }}
                </td>
                <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                  <span :class="[statusStyles[transaction.status], 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                    {{ transaction.status }}
                  </span>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <time :datetime="transaction.datetime">{{ transaction.date }}</time>
                </td>
              </tr>
              </tbody>
            </table>
            <!-- Pagination -->
            <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
              <div class="hidden sm:block">
                <p class="text-sm text-gray-700">
                  Showing
                  {{ ' ' }}
                  <span class="font-medium">1</span>
                  {{ ' ' }}
                  to
                  {{ ' ' }}
                  <span class="font-medium">10</span>
                  {{ ' ' }}
                  of
                  {{ ' ' }}
                  <span class="font-medium">20</span>
                  {{ ' ' }}
                  results
                </p>
              </div>
              <div class="flex-1 flex justify-between sm:justify-end">
                <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                  Previous
                </a>
                <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                  Next
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- Trigger infinite scroll-->
    <div ref="trigger"/>

  </div>
</template>

<script>

import CreateTreatment from "@/components/CreateTreatment";
import MyUtil from "@/util/MyUtil";
import {onMounted, ref} from "@vue/runtime-core";
import {
  CashIcon,
  ChevronRightIcon,
} from '@heroicons/vue/solid'
import router from "@/router";
import axios from "axios";

const transactions = [
  {
    id: 1,
    name: 'Vaksin ND IB 100ml',
    href: '#',
    amount: 'Rp 350.000',
    currency: 'X 3 Botol',
    status: 'success',
    date: 'July 11, 2020',
    datetime: '2020-07-11',
  },
  // More transactions...
]

const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-yellow-100 text-yellow-800',
  failed: 'bg-gray-100 text-gray-800',
}

export default {
  name: "Treatment",

  components: {
    CreateTreatment,
    CashIcon,
    ChevronRightIcon,
  },

  props: [
    'pulletId'
  ],

  setup(props) {

    let properties = ref({
      show: false,
      pulletId: props.pulletId,
      title: 'Catat Belanja Perawatan'
    });

    let treatmentCosts = ref([]);

    let trigger = ref();

    let getTreatmentCostOptions = ref({
      url: `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/treatment-costs`,
      next_page_url: `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/treatment-costs`,
      observer: true,
      setObserver: setObserver,
      refresh: true
    });

    let setObserver = onMounted(()=> {
      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (getTreatmentCostOptions.value.observer) {
              getTreatmentCost()
            }
          }
        })
      }, {
        root: null,
        threshold: 0
      });
      observer.observe(trigger.value);
    })

    function togglePanelRecording() {
      properties.value.show = !properties.value.show;
      // getTreatmentCost();
    }

    function getTreatmentCost(refresh = false) {
      axios.get((refresh ? getTreatmentCostOptions.value.url : getTreatmentCostOptions.value.next_page_url))
          .then((response) => {

            // Reset refresh to false when it's true
            refresh ? getTreatmentCostOptions.value.refresh = false : '';

            // Convert data from object to array
            let toArray = Object.keys(response.data.data.data).map((key) => response.data.data.data[key])

            // if refresh recoding change to empty for refresh data newest
            refresh ? treatmentCosts.value = [] : '';
            refresh ? (getTreatmentCostOptions.value.observer = true) : ''

            // Push data to array collection
            toArray.forEach((res)=> {
              treatmentCosts.value.push(res);
            })

            // set next_page_url for infinite scroll
            getTreatmentCostOptions.value.next_page_url = response.data.data.next_page_url ?? getTreatmentCostOptions.value.url

            // stop observer if latest page viewed
            if (response.data.data.next_page_url === null) {
              getTreatmentCostOptions.value.observer = false;
            }

          }).catch((err)=>{
            console.log(err.response);
          })
    }

    return {
      properties,
      togglePanelRecording,
      transactions,
      statusStyles,
      treatmentCosts,
      MyUtil,
      getTreatmentCost,
      trigger
    }
  }
}
</script>
