<template>
  <div class="flex-shrink-0 px-4 py-4 flex justify-end shadow-2xl">
    <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="togglePanelRecording">
      Batal
    </button>
    <SubmitButton :name="submitProperties.name" :is-loading="submitProperties.loading" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      Simpan
    </SubmitButton>
  </div>
</template>

<script>

import SubmitButton from "@/components/SubmitButton";

export default {

  name: "SlideOverSubmitForm",

  components: {SubmitButton},

  props: [
      'submitProperties'
  ],

  setup(props, {emit}) {

    function togglePanelRecording() {
      emit('togglePanelRecording');
    }

    return {
      togglePanelRecording
    }

  }

}

</script>

<style scoped>

</style>
