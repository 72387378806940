<template>

    <!-- Page header -->
    <div class="bg-white shadow">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div class="flex-1 min-w-0">
            <!-- Profile -->
            <div class="flex items-center" v-if="user">
              <img :src="user.photoURL" referrerpolicy="no-referrer" class="hidden h-16 w-16 rounded-full sm:block" alt="" />
              <div>
                <div class="flex items-center">
                  <img class="h-16 w-16 rounded-full sm:hidden" :src="user.photoURL" referrerpolicy="no-referrer" alt="" />
                  <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    Good morning, {{ user.displayName }}
                  </h1>
                </div>
                <dl class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt class="sr-only">Company</dt>
                  <dd class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                    <OfficeBuildingIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Duke street studio
                  </dd>
                  <dt class="sr-only">Account status</dt>
                  <dd class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                    <CheckCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                    Verified account
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <router-link to="/hen-houses/create" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
              Buat Kandang
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8">
      <!-- List Hen House-->
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 v-if="henHouses.length > 0" class="text-lg leading-6 font-medium text-gray-900">List Kandang</h2>
        <div v-else>
          <EmptyHenHouse/>
        </div>
        <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <!-- Hen House Lists -->
          <div v-for="{address, href, icon, name} in henHouses" :key="name" class="bg-white overflow-hidden shadow rounded-lg">
            <div class="p-5">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <component :is="icon" class="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div class="ml-5 w-0 flex-1">
                  <dl>
                    <dt class="text-sm font-medium text-gray-500 truncate">
                      {{ address }}
                    </dt>
                    <dd>
                      <div class="text-lg font-medium text-gray-900">
                        {{ name }}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-5 py-3">
              <div class="text-sm">
                <router-link :to="href" class="font-medium text-cyan-700 hover:text-cyan-900">
                  Lihat Kandang
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
      FCM Token testing
    </h2>

    <div class="px-6 break-words bg-white p-2 rounded-xl mx-4 mt-2">
      {{ fcm.token }}
    </div>

</template>

<script>

import axios from "axios";
import { onMounted, ref } from "vue"
import EmptyHenHouse from "@/views/henHouse/EmptyHenHouse";

import {
  HomeIcon
} from "@heroicons/vue/outline/esm";

import {
  CashIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  CheckCircleIcon
} from '@heroicons/vue/solid'
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {getAuth} from "firebase/auth";


const transactions = [
  {
    id: 1,
    name: 'Payment to Molly Sanders',
    href: '#',
    amount: '$20,000',
    currency: 'USD',
    status: 'success',
    date: 'July 11, 2020',
    datetime: '2020-07-11',
  },
  // More transactions...
]

const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-yellow-100 text-yellow-800',
  failed: 'bg-gray-100 text-gray-800',
}

export default {

  name: "Dashboard",

  components: {
    CashIcon,
    ChevronRightIcon,
    OfficeBuildingIcon,
    CheckCircleIcon,
    EmptyHenHouse
  },

  setup() {

    let henHouses = ref({});
    let collectData = [];
    const fcm = ref({
      token: ''
    })

    let user = ref({});
    user = getAuth().currentUser;

    function getHenHouses() {
      axios.get('/api/hen-houses').then((response)=>{
        response.data.data.forEach((item)=>{
          collectData.push({
            'address': item["address"],
            'href': '/hen-houses/' + item["id"],
            'icon': HomeIcon,
            'name': item["name"]
          })
        })
        henHouses.value = collectData;
      })
    }

    onMounted(() => {
      getHenHouses();
    });

    const messaging = getMessaging();
    getToken(messaging, { vapidKey: 'BEm_Hc7U-8zdd-Vkcr6eC7FBhDmluL0hFXSEqM1s_irRgdL30gIdzqfaFC0WrEXDG6e1vlobmitjj4KbXo11tQo' }).then((currentToken) => {
      if (currentToken) {
        fcm.value.token = currentToken
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        fcm.value.token = 'No registration token available. Request permission to generate one.'
        console.log(fcm.value.token);
        // ...
      }
    }).catch((err) => {
      fcm.value.token = 'An error occurred while retrieving token. ' + err;
      console.log(fcm.value.token);
      // ...
    });

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // ...
    });

    return {
      henHouses,
      user,
      transactions,
      statusStyles,
      CashIcon,
      ChevronRightIcon,
      EmptyHenHouse,
      fcm
    };
  }

}
</script>
