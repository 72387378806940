<template>

  <Notification :show="isShow.status" @updateParentNotification="closeNotification"></Notification>

  <div class="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div class="max-w-max mx-auto">
      <main class="sm:flex">
        <p class="text-4xl font-extrabold text-indigo-600 sm:text-5xl">
          <svg xmlns="http://www.w3.org/2000/svg" class="md:h-1/2 h-full md:w-full w-1/4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
        </p>
        <div class="sm:ml-6">
          <div class="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Verifikasi e-mail address</h1>
            <p class="mt-1 text-base text-gray-500 bg-green-100 px-3 py-2 rounded inline-block mt-8">Link verifikasi sudah dikirim ke inbox e-mail kamu. Silahkan cek dan verifikasi.</p>
          </div>
          <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
            <button @click="checkStatus" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Check Status
            </button>
            <a href="#" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Contact support
            </a>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import Notification from "@/components/Notification";
import {ref} from "@vue/runtime-core";
import authStore from "@/store/auth";
import router from "@/router";

export default {

  name: "EmailVerification",

  components: {
    Notification
  },

  setup() {

    let isShow = ref({
      status: false
    });

    function closeNotification() {
      isShow.value.status = false;
    }

    function checkStatus() {
      axios.get('/api/user').then(()=> {
        authStore.dispatch('user').then(()=>{
          router.replace({
            name: 'Dashboard'
          })
        })
      }).catch((err)=> {
        if (err.response.status === 403) {
          isShow.value.status = true
        }
      })
    }

    return {
      checkStatus,
      closeNotification,
      isShow
    }

  }

}
</script>

<style scoped>

</style>
