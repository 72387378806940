<template>
  <CreateOperational
      :properties="properties"
      @togglePanelRecording="togglePanelRecording"
      @getOperationals="getOperationals"
  />

  <ModalDeleteOperational
      v-model:showDelete="modalProperties.showDelete"
      :delete-url="modalProperties.url"
      :operational-data="operationalData"
      @getOperationals="getOperationals"
  />

  <div class="py-2 md:px-8 px-3 bg-white">

    <LoadingElipsis v-if="operationalProperties.loadingOperationals"/>

    <div v-else>
      <EmptyProject
          v-if="operationals.length === 0"
          btn-title="Catat Pengeluaran"
          description="Ayo buat catatan operasional pertama mu"
          head-title="Belum ada catatan operasional"
          @click="togglePanelRecording"
      />
      <div v-else>
        <div class="flex justify-end">
          <button @click="togglePanelRecording" type="button" class="mb-6 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
            Catat Pengeluaran
          </button>
        </div>

        <!-- Activity list (smallest breakpoint only) -->
        <div class="shadow sm:hidden">
          <ul class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
            <li v-for="(operational, index) in operationals" :key="operational['id']">
              <Smallitem
                :index="index"
                :properties="properties"
                :item-id="operational['id']"
                v-model:payLoading="modalProperties.loading"
                v-model:payLoadingId="modalProperties.loadingId"
                v-model:showDelete="modalProperties.showDelete"
                v-model:deleteId="modalProperties.deleteId"
                v-model:loadingDelete="modalProperties.loadingDelete"
                :actionEdit="false"
                :actionPay="false"
                :actionDelete="true"
                @togglePanelRecording="togglePanelRecording"
                @toggleModalDelete="toggleModalDelete(operational['id'])"
              >
                <span class="flex items-center space-x-4">
                  <span class="flex-1 flex space-x-2 truncate">
                    <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span class="flex flex-col text-gray-500 text-sm truncate">
                      <span class="truncate">{{ operational.name }}</span>
                      <span
                      ><span class="text-gray-900 font-medium">{{ MyUtil.toRupiah(operational['nominal']) }}</span> X {{ operational['quantity'] }}</span
                      >
                      <time>{{ MyUtil.toLocaleDateString(operational['date']) }}</time>
                    </span>
                  </span>
                  <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Smallitem>
            </li>
          </ul>
        </div>

        <!-- Activity table (small breakpoint and up) -->
        <div class="hidden sm:block">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col mt-2">
              <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                  <tr>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Transaction
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                      Status
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="transaction in operationals" :key="transaction.id" class="bg-white">
                    <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <div class="flex">
                        <a href="#" class="group inline-flex space-x-2 truncate text-sm">
                          <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          <p class="text-gray-500 truncate group-hover:text-gray-900">
                            {{ transaction.name }}
                          </p>
                        </a>
                      </div>
                    </td>
                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                      <span class="text-gray-900 font-medium">{{ transaction['nominal'] }} </span>
                      {{ transaction['quantity'] }}
                    </td>
                    <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                      <span :class="[statusStyles[transaction.status], 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                        {{ transaction.status }}
                      </span>
                    </td>
                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                      <time :datetime="transaction.datetime">{{ transaction.date }}</time>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!-- Pagination -->
                <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                  <div class="hidden sm:block">
                    <p class="text-sm text-gray-700">
                      Showing
                      {{ ' ' }}
                      <span class="font-medium">1</span>
                      {{ ' ' }}
                      to
                      {{ ' ' }}
                      <span class="font-medium">10</span>
                      {{ ' ' }}
                      of
                      {{ ' ' }}
                      <span class="font-medium">20</span>
                      {{ ' ' }}
                      results
                    </p>
                  </div>
                  <div class="flex-1 flex justify-between sm:justify-end">
                    <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      Previous
                    </a>
                    <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      Next
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="trigger"/>

  </div>
</template>

<script>

import CreateOperational from "@/components/CreateOperational";
import {onMounted, ref} from "@vue/runtime-core";
import MyUtil from "@/util/MyUtil";
import {
  CashIcon,
  ChevronRightIcon,
} from '@heroicons/vue/solid'
import axios from "axios";
import router from "@/router";
import Smallitem from "@/components/Smallitem";
import ModalDeleteOperational from "@/components/ModalDeleteOperational";
import LoadingElipsis from "@/components/LoadingElipsis";
import EmptyProject from "@/components/EmptyProject";

const transactions = [
  {
    id: 1,
    name: 'Payment to Molly Sanders',
    href: '#',
    amount: '$20,000',
    currency: 'USD',
    status: 'success',
    date: 'July 11, 2020',
    datetime: '2020-07-11',
  },
  // More transactions...
]

const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-yellow-100 text-yellow-800',
  failed: 'bg-gray-100 text-gray-800',
}

export default {

  name: "Operational",

  components: {
    CreateOperational,
    CashIcon,
    ChevronRightIcon,
    Smallitem,
    ModalDeleteOperational,
    LoadingElipsis,
    EmptyProject
  },

  props: [
    'pulletId'
  ],

  setup(props) {

    let properties = ref({
      show: false,
      title: 'Operasional',
      pulletId: props.pulletId
    });

    const trigger = ref();

    let operationalProperties = ref({
      url : `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/operationals`,
      next_page_url: null,
      observer: true,
      setObserver: setObserver,
      refresh: true,
      loadingOperationals: true,
    });

    let setObserver = onMounted(()=> {
      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (operationalProperties.value.observer) {
              getOperationals()
            }
          }
        })
      }, {
        root: null,
        threshold: 0
      });
      observer.observe(trigger.value);
    })

    let operationals = ref([]);

    let modalProperties = ref({
      showPayment: false,
      showDelete: false,
      pulletId: props.pulletId,
      sellingId: '',
      selling: '',
      loading: false,
      loadingId: null,
      url: '',
      deleteId: null,
      loadingDelete: false,
    });

    let operationalData = ref({
      "id": null,
      "pullet_id": null,
      "date": "",
      "name": "",
      "nominal": null,
      "quantity": null,
      "type": "",
      "description": null,
      "created_at": "",
      "updated_at": ""
    });

    function togglePanelRecording() {
      properties.value.show = !properties.value.show;
    }

    function getOperationals(refresh = false) {
      axios.get(operationalProperties.value.next_page_url ?? operationalProperties.value.url).then((response) => {

        operationalProperties.value.loadingOperationals = false;

        // Reset refresh to false when it's true
        refresh ? operationalProperties.value.refresh = false : '';

        // operationals.value = response.data.data.data;
        // Convert data from object to array
        let toArray = Object.keys(response.data.data.data).map((key) => response.data.data.data[key])

        // if refresh recoding change to empty for refresh data newest
        refresh ? operationals.value = [] : '';
        refresh ? (operationalProperties.value.observer = true) : ''

        // Push data to array collection
        toArray.forEach((res)=> {
          operationals.value.push(res);
        })

        // set next_page_url for infinite scroll
        operationalProperties.value.next_page_url = response.data.data.next_page_url ?? operationalProperties.value.url

        // set url for edit single data
        // properties.value.url = operationalProperties.value.url

        // stop observer if latest page viewed
        if (response.data.data.next_page_url === null) {
          operationalProperties.value.observer = false;
        }

      }).catch((err)=>{
        console.log(err.response);
      })
    }

    function toggleModalDelete(operationalId) {
      modalProperties.value.url = operationalProperties.value.url + '/' + operationalId
      axios.get(modalProperties.value.url).then((response)=>{
        modalProperties.value.showDelete = true;
        modalProperties.value.loadingDelete = false;
        operationalData.value = response.data.data;
      })
    }

    return {
      togglePanelRecording,
      properties,
      transactions,
      statusStyles,
      getOperationals,
      operationals,
      MyUtil,
      modalProperties,
      toggleModalDelete,
      operationalData,
      operationalProperties,
      trigger
    }

  },

}

</script>
