<template>
  <Notification :properties="notificationProperties" @toggleNotification="toggleNotification" />
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <router-link to="/">
        <img alt="Workflow" class="mx-auto h-12 w-auto"
             src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"/>
      </router-link>
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Masuk ke Akun Kamu
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Atau
        {{ ' ' }}
        <router-link class="font-medium text-indigo-600 hover:text-indigo-500" to="/register">
          Daftar Gratis
        </router-link>
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="">
          <div>
            <label class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input v-model="user.email" autocomplete="email" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" name="email" required=""
                     type="email"/>
            </div>
            <p v-if="validation['email']" class="text-sm text-red-500">
              {{validation['email'][0]}}
            </p>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div class="mt-1">
              <input v-model="user.password" autocomplete="current-password" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" name="password" required=""
                     type="password"/>
            </div>
            <p v-if="validation['password']" class="text-sm text-red-500">
              {{validation['password'][0]}}
            </p>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <label class="ml-2 block text-sm text-gray-900">
              </label>
            </div>

            <div class="text-sm">
              <a class="font-medium text-indigo-600 hover:text-indigo-500" href="#">
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <SubmitButton @click.prevent="signInWithGoogleEmailAndPassword" name="Sign In" :is-loading="check.loadingLoginPass" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"/>
          </div>

        </form>

        <div class="text-center mt-4">
          Atau
        </div>

        <div class="flex items-center mt-4">
          <SubmitButton :is-google-icon="true" type="button" name="Masuk menggunakan Google" @click.prevent="signInWithGoogle" :is-loading="check.loadingLoginGoogle" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { reactive, ref } from '@vue/reactivity'
import authStore from '../../store/auth'
import SubmitButton from "@/components/SubmitButton";
import Notification from "@/components/Notification";
import {getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword} from "firebase/auth";
import router from "@/router";

export default {

  name: 'Login',

  components: {
    SubmitButton,
    Notification,
  },

  setup() {

    const user = reactive({
      email: null,
      password: null
    });

    if (authStore.state.authStatus) {
      router.replace({ name: 'Dashboard' });
    }

    // Sign in with Google Account
    async function signInWithGoogle() {
      check.loadingLoginGoogle = true;
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      provider.addScope('email');
      await signInWithPopup(auth, provider)
      .then(() => {

      }).catch((error) => {
        check.loadingLoginGoogle = false;
        // Handle Errors here.
        const errorCode = error.code;
        console.log(errorCode)
        const errorMessage = error.message;
        console.log(errorMessage)
        // The email of the user's account used.
        const email = error.email;
        console.log(email)
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(credential)
        notificationProperties.value.show = true;
        notificationProperties.value.status = error.message;
        notificationProperties.value.message = error.message;
        notificationProperties.value.title = 'Gagal Login';
      });
    }

    // Sign in with Email and Password
    async function signInWithGoogleEmailAndPassword() {
      check.loadingLoginPass = true;
      const auth = getAuth();
      signInWithEmailAndPassword(auth, user.email, user.password)
          .then(() => {
            // Signed in
          })
          .catch((error) => {
            const errorCode = error.code;
            console.log(errorCode)
            const errorMessage = error.message;
            console.log(errorMessage)
            check.loadingLoginPass = false;
            notificationProperties.value.show = true;
            notificationProperties.value.status = error.message;
            notificationProperties.value.message = error.message;
            notificationProperties.value.title = 'Gagal Login';
          });
    }

    let check = reactive({
      loadingLoginPass: false,
      loadingLoginGoogle: false,
      internalServerError: false,
      unknowError: false,
      login: false
    });

    let notificationProperties = ref({
      show: false,
      title: '',
      message: '',
      status: 'failed'
    })

    const validation = ref({});

    function toggleNotification() {
      notificationProperties.value.show = !notificationProperties.value.show
    }

    let login = async () => {
      check.loading = true;
      await authStore.dispatch('login', user)
          .then(() => {
            check.login = true;
          }).catch((err) => {
            notificationProperties.value.show = true;
            if (!err.status) {
              check.loading = false;
              notificationProperties.value.title = 'Error'
              notificationProperties.value.message = err.message
            }
            switch(err.response.status) {
              case 422:
                check.loading = false;
                validation.value = err.response.data.errors;
                notificationProperties.value.title = 'Username / Password Salah'
                notificationProperties.value.message = 'Periksa kembali username dan password yang kamu masukan'
                break;
              case 500:
                check.loading = false;
                check.internalServerError = true
                notificationProperties.value.title = err.response.statusText
                notificationProperties.value.message = 'Mohon maaf, kami akan segera memperbaiki masalah ini'
                break;
              default:
                check.loading = false;
                check.unknowError = true
                notificationProperties.value.title = 'Error'
                notificationProperties.value.message = err.response.statusText
            }
          });
    }
    return { signInWithGoogleEmailAndPassword, user, login, check, validation,toggleNotification, notificationProperties, signInWithGoogle }
  }
}
</script>
