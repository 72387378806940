<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Daftar Gratis
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="register">

          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Nama
            </label>
            <div class="mt-1">
              <input v-model="user.name" name="name" type="text" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
            <p class="mt-2 text-sm text-red-600" v-if="validation.name">
              {{validation.name[0]}}
            </p>
          </div>

          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input v-model="user.email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
            <p class="mt-2 text-sm text-red-600" v-if="validation.email">
              {{validation.email[0]}}
            </p>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div class="mt-1">
              <input v-model="user.password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
            <p class="mt-2 text-sm text-red-600" v-if="validation.password">
              {{validation.password[0]}}
            </p>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              Confirm Password
            </label>
            <div class="mt-1">
              <input v-model="user.password_confirmation" name="confirm_password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
            <p class="mt-2 text-sm text-red-600" v-if="validation.password_confirmation">
              {{validation.password_confirmation[0]}}
            </p>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <label for="remember-me" class="ml-2 block text-sm text-gray-900">

              </label>
            </div>

            <div class="text-sm">
              <router-link to="/login" class="font-medium text-indigo-600 hover:text-indigo-500">
                Sudah punya akun ?
              </router-link>
            </div>
          </div>

          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Daftar Gratis
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import {reactive, ref} from "@vue/reactivity";
  import router from "@/router";
  import authStore from '../../store/auth';

  export default {
    setup() {
      const user = reactive({
        name: '',
        email: '',
        password: '',
        password_confirm: ''
      });

      const validation = ref([]);

      async function register() {
        await axios.get('/sanctum/csrf-cookie')
        await axios.post('/api/register', user)
            .then(()=>{
              authStore.dispatch('login', user).then(()=> {
                router.replace('/email-verification')
              })
            })
            .catch((err) => {
              validation.value = err.response.data.errors;
            })
        await console.log(validation)
      }

      return {
        user,
        register,
        validation
      }
    }
  }
</script>
