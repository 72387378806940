import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import EmailVerification from '../views/auth/EmailVerification.vue'
import CoreDashboard from "@/views/CoreDashboard";
import Dashboard from "@/views/Dashboard";
import CreateHenHouse from "@/views/henHouse/CreateHenHouse";
import ShowHenHouse from "@/views/henHouse/ShowHenHouse";
import CreatePullet from "@/views/pullet/CreatePullet";
import Recording from "@/views/pullet/Recording";
import Info from "@/views/pullet/InfoPullet";
import FeedPullet from "@/views/pullet/FeedPullet";
import Selling from "@/views/pullet/Selling";
import Treatment from "@/views/pullet/Treatment";
import CashFlow from "@/views/pullet/CashFlow";
import Operational from "@/views/pullet/Operational";
import Capital from "@/views/pullet/Capital";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/email-verification',
    name: 'EmailVerification',
    component: EmailVerification,
  },
  {
    path: '/dashboard',
    component: CoreDashboard,
    children: [{
      path: 'main',
      name: 'Dashboard',
      component: Dashboard
    }]
  },
  {
    path: '/hen-houses',
    component: CoreDashboard,
    children: [{
      path: 'create',
      name: 'CreateHenHouse',
      component: CreateHenHouse
    }]
  },
  {
    path: '/hen-houses',
    component: CoreDashboard,
    children: [
      {
        path: ':id',
        name: 'ShowHenHouse',
        component: ShowHenHouse,
        children: [
          {
            path: '',
            name: 'Info',
            component: Info
          },
          {
            path: 'recording',
            name: 'Recording',
            component: Recording
          },
          {
            path: 'selling',
            name: 'Selling',
            component: Selling
          },
          {
            path: 'treatment',
            name: 'Treatment',
            component: Treatment
          },
          {
            path: 'feed',
            name: 'Feed',
            component: FeedPullet
          },{
            path: 'cash-flow',
            name: 'CashFlow',
            component: CashFlow
          },
          {
            path: 'operational',
            name: 'Operational',
            component: Operational
          },
          {
            path: 'capital',
            name: 'Capital',
            component: Capital
          }
        ]
      },
      {
        path: ':id/pullet/create',
        name: 'CreatePullet',
        component: CreatePullet
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
