<template>
  <CreateStock
      v-if="properties['feedTypes']"
      v-model:id="feedData.id"
      v-model:supplier="feedData.supplier"
      v-model:date="feedData.date"
      v-model:feed_type="feedData.feed_type"
      v-model:status="feedData.status"
      v-model:price_per_kg="feedData.price_per_kg"
      v-model:price_per_kg_with_dots="feedData.price_per_kg_with_dots"
      v-model:mass="feedData.mass"
      v-model:mass_with_dots="feedData.mass_with_dots"
      v-model:mass_in_stock="feedData.mass_in_stock"
      v-model:mass_in_stock_with_dots="feedData.mass_in_stock_with_dots"
      v-model:shipping_cost="feedData.shipping_cost"
      v-model:shipping_cost_with_dots="feedData.shipping_cost_with_dots"
      :properties="properties"
      @togglePanelRecording="togglePanelRecording"
      @getFeedStocks="getFeedStocks"
  />

  <div class="py-2 md:px-8 px-3 bg-white">

    <div class="flex justify-end" v-if="properties">
      <button @click="togglePanelRecording" type="button" class="mb-6 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
        Masukan Stok
      </button>
    </div>

    <!-- Activity list (smallest breakpoint only) -->
    <div class="shadow sm:hidden">

      <div v-if="feedStocks">
        <FeedStock :properties="properties" @togglePanelRecording="togglePanelRecording" :feedStocks="feedStocks['in_used']"/>
        <FeedStock :properties="properties" @togglePanelRecording="togglePanelRecording" :feedStocks="feedStocks['in_stock']"/>
        <FeedStock :properties="properties" @togglePanelRecording="togglePanelRecording" :feedStocks="feedStocks['out_of_stock']"/>
      </div>
    </div>

    <!-- Activity table (small breakpoint and up) -->
    <div class="hidden sm:block">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col mt-2">
          <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
              <tr>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Transaction
                </th>
                <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                  Status
                </th>
                <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="transaction in transactions" :key="transaction.id" class="bg-white">
                <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div class="flex">
                    <a :href="transaction.href" class="group inline-flex space-x-2 truncate text-sm">
                      <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                      <p class="text-gray-500 truncate group-hover:text-gray-900">
                        {{ transaction.name }}
                      </p>
                    </a>
                  </div>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <span class="text-gray-900 font-medium">{{ transaction.quantity }} </span>
                  {{ transaction.currency }}
                </td>
                <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                  <span :class="[statusStyles[transaction.status], 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                    {{ transaction.status }}
                  </span>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <time :datetime="transaction.datetime">{{ transaction.date }}</time>
                </td>
              </tr>
              </tbody>
            </table>
            <!-- Pagination -->
            <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
              <div class="hidden sm:block">
                <p class="text-sm text-gray-700">
                  Showing
                  {{ ' ' }}
                  <span class="font-medium">1</span>
                  {{ ' ' }}
                  to
                  {{ ' ' }}
                  <span class="font-medium">10</span>
                  {{ ' ' }}
                  of
                  {{ ' ' }}
                  <span class="font-medium">20</span>
                  {{ ' ' }}
                  results
                </p>
              </div>
              <div class="flex-1 flex justify-between sm:justify-end">
                <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                  Previous
                </a>
                <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                  Next
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {
  CashIcon,
} from '@heroicons/vue/solid'

import CreateStock from "@/components/CreateStock";
import FeedStock from "@/views/pullet/Component/FeedStock";
import {ref} from "@vue/runtime-core";
import axios from "axios";
import router from "@/router";
import MyUtil from "@/util/MyUtil";

const transactions = [
  {
    id: 1,
    name: 'Jagung dari Acep',
    href: '#',
    quantity: '1.700',
    currency: 'Kg',
    status: 'success',
    date: 'July 11, 2020 (1 Bulan Yang Lalu)',
    datetime: '2020-07-11',
  },
];

const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-yellow-100 text-yellow-800',
  failed: 'bg-gray-100 text-gray-800',
}

export default {

  name: "FeedPullet",

  props: [
      'pulletId'
  ],

  components: {
    CashIcon,
    FeedStock,
    CreateStock
  },

  setup(props) {

    let properties = ref({
      show: false,
      title: 'Memasukan Stok Pakan',
      pulletId: props.pulletId,
      feedTypes: '',
      url: '',
    });

    let feedData = ref({
      id: null,
      date: MyUtil.dateNow(),
      supplier: '',
      feed_type: '',
      status: null,
      price_per_kg: null,
      price_per_kg_with_dots: '',
      mass: null,
      mass_with_dots: '',
      mass_in_stock: null,
      mass_in_stock_with_dots: '',
      shipping_cost: null,
      shipping_cost_with_dots: ''
    })

    const feedStocks = ref({});

    let url = `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/feed-stocks`;
    properties.value.url = url;

    function togglePanelRecording(responseFeedData) {
      if(typeof responseFeedData == 'object') {
        if (Number.isInteger(responseFeedData.id)) {
          console.log('masuk')
            feedData.value = responseFeedData
            feedData.value.price_per_kg_with_dots = MyUtil.numberWithDots(responseFeedData.price_per_kg)
            feedData.value.mass_with_dots = MyUtil.numberWithDots(responseFeedData.mass)
            feedData.value.mass_in_stock_with_dots = MyUtil.numberWithDots(responseFeedData.mass_in_stock)
            feedData.value.shipping_cost_with_dots = MyUtil.numberWithDots(responseFeedData.shipping_cost)
            properties.value.url = url + '/' + responseFeedData.id
            properties.value.show = true
          console.log(feedData.value)
        } else {
          properties.value.show = !properties.value.show;
          properties.value.url = url;
        }
      } else {
        properties.value.show = !properties.value.show;
        properties.value.url = url;
      }

      if (!properties.value.show) {
        feedData.value.id = null;
        feedData.value.date = MyUtil.dateNow();
        feedData.value.supplier = '';
        feedData.value.feed_type = '';
        feedData.value.price_per_kg = null;
        feedData.value.price_per_kg_with_dots = null;
        feedData.value.mass = null;
        feedData.value.mass_with_dots = null;
        feedData.value.shipping_cost = null;
        feedData.value.shipping_cost_with_dots = null;
        feedData.value.status = null;
        feedData.value.mass_in_stock = null;
        feedData.value.mass_in_stock_with_dots = null;
      }
    }

    function getFeedStocks() {
      axios.get(url).then(res => {
        feedStocks.value = res.data.data;
        if (res.data.data.feed_type === 'Aduk Sendiri') {
          properties.value.feedTypes = [
            'Jagung Kering Pipil',
            'Konsentrat',
            'Bekatul',
          ]
        } else if (res.data.data.feed_type === 'Pakan Jadi Pabrik') {
          properties.value.feedTypes = [
            'Pakan Jadi',
          ]
        } else if (res.data.data.feed_type === 'Dibuat Sendiri') {
          properties.value.feedType = [
            'Pakan Jadi',
          ]
        }
      })
    }

    getFeedStocks()

    return {
      transactions,
      statusStyles,
      feedStocks,
      properties,
      togglePanelRecording,
      getFeedStocks,
      feedData
    }
  }

}
</script>
