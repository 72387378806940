<template>

  <!--  Header-->
  <div class="bg-white shadow">
    <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
      <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
        <div class="flex-1 min-w-0 text-xl font-bold">
          Buat Kandang
        </div>
      </div>
    </div>
  </div>


  <!-- Forms -->
  <form @submit.prevent="createHenHouse">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-white shadow mt-8">
    <div class="md:w-1/2 w-full">

      <div>
        <label for="name" class="block text-sm font-medium text-gray-700">Nama Kandang</label>
        <div class="mt-1">
          <input v-model="henHouse['name']" type="text" autocomplete="off" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Kandang Petelur" />
        </div>
      </div>

      <div class="mt-4">
        <label for="building_at" class="block text-sm font-medium text-gray-700">Tanggal Pembangunan</label>
        <div class="mt-1">
          <input v-model="henHouse['building_at']" type="date" autocomplete="off" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="7 Agustus 1945" />
        </div>
      </div>

      <div class="mt-4">
        <label for="capacity" class="block text-sm font-medium text-gray-700">Kapasitas Kandang</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input v-model="henHouse['capacity']" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md" placeholder="1000" />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            Ekor
          </div>
        </div>
      </div>

      <div class="mt-4">
        <label for="address" class="block text-sm font-medium text-gray-700">Alamat</label>
        <div class="mt-1">
          <textarea v-model="henHouse['address']" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Jl Kenangan Mantan" />
        </div>
      </div>

      <div class="mt-4">
        <SubmitButton name="Buat Kandang"/>
      </div>

    </div>
  </div>
  </form>

</template>

<script>

import SubmitButton from "@/components/SubmitButton";
import {ref} from "@vue/runtime-core";
import axios from "axios";
import router from "@/router";
import auth from "@/store/auth";

export default {

  components: {
    SubmitButton
  },

  name: "CreateHenHouse",

  setup() {

    const henHouse = ref({})

    function createHenHouse() {
      axios.get('/sanctum/csrf-cookie')

      // Add default is_used value
      henHouse.value.is_used = 0;

      // User ID
      henHouse.value.user_id = auth.state.user.id;

      axios.post('/api/hen-houses', henHouse.value)
          .then((response)=> {
            if (response.status === 201) {
              router.replace('/hen-houses/' + response.data.data.id)
            }
          }).catch((err)=>{
            console.log(err.response)
          })
    }

    return {
      createHenHouse,
      henHouse
    }
  }

}
</script>

<style scoped>

</style>
