<template>
  <SlideOver :properties="properties">
    <form @submit.prevent="submitOperational" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div class="flex-1 h-0 overflow-y-auto">
        <SlideOverHead :properties="properties" @togglePanelRecording="togglePanelRecording" />
        <!--
         1. Tanggal,
         2. Judul
         3. Jenis Operasional
         4. Nominal
         5. Keterangan
         -->
        <div class="flex-1 flex flex-col justify-between px-4">
          <div class="mt-4">
            <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Tanggal</label>
            <div class="mt-1">
              <input type="date" v-model="operational.date" required class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="mt-4">
            <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Nama Operasional</label>
            <input v-model="operational.name" type="text" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" placeholder="Nama Operasional" required>
          </div>
          <div class="mt-4">
            <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Jenis Operasional</label>
            <select v-model="operational.type" required class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
              <option value="" disabled>--pilih--</option>
              <option v-for="item in itemTypes" :key="item">{{ item }}</option>
            </select>
          </div>

          <div class="flex">
            <div class="mt-4">
              <label class="block text-sm font-medium text-gray-700">Biaya</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  Rp
                </div>
                <input :value="operationalValue.nominal" @input="nominal($event.target.value)" required type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" placeholder="0" />
              </div>
            </div>
            <div class="mt-4 ml-1">
              <label class="block text-sm font-medium text-gray-700">Jumlah</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  X
                </div>
                <input :value="operationalValue.quantity" @input="quantity($event.target.value)" required type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" placeholder="1" />
              </div>
            </div>
          </div>

          <div class="mt-4 pl-2 text-gray-500">
            Total {{ MyUtil.toRupiah(operational.nominal * operational.quantity)}}
          </div>

          <div class="mt-4">
            <label class="block text-sm font-medium text-gray-700">Keterangan</label>
            <textarea v-model="operational.description" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>

        </div>
      </div>
      <SlideOverSubmitForm :submit-properties="submitOperationalProperties" @togglePanelRecording="togglePanelRecording" />
    </form>
  </SlideOver>
</template>

<script>

import SlideOver from "@/components/SlideOver";
import SlideOverSubmitForm from "@/components/SlideOverSubmitForm";
import SlideOverHead from "@/components/SlideOverHead";
import MyUtil from "@/util/MyUtil";
import {ref} from "@vue/runtime-core";
import router from "@/router";
import axios from "axios";

export default {

  name: "CreateOperational",

  props: [
      'properties'
  ],

  components: {
    SlideOver,
    SlideOverSubmitForm,
    SlideOverHead
  },

  setup(props, {emit}) {

    let operational = ref({
      date: MyUtil.todayFormFormat(),
      name: '',
      type: '',
      nominal: '',
      quantity: '',
      description: ''
    });

    let operationalValue = ref({
      nominal: '',
      quantity: '',
    });

    let itemTypes = ref([
        'Ojek',
        'Bahan bakar',
        'Jasa',
        'Sewa',
        'Beli barang',
        'Gaji pegawai',
    ]);

    let submitOperationalProperties = ref({
      name : 'Simpan',
      loading: false,
    })

    function togglePanelRecording() {
      emit('togglePanelRecording');
      operational.value.nominal = '';
      operational.value.name = '';
      operational.value.date = MyUtil.dateNow();
      operational.value.type = '';
      operational.value.quantity = '';
      operational.value.description = '';
      operationalValue.value.quantity = "";
      operationalValue.value.nominal = "";
    }

    let url = `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.properties.pulletId}/operationals`;
    function submitOperational() {
      submitOperationalProperties.value.loading = true;
      axios.get('/sanctum/csrf-cookie');
      axios.post(url, operational.value).then(() => {
        submitOperationalProperties.value.loading = false;
        togglePanelRecording();
        emit('getOperationals', true);
      }).catch(err => {
        console.log(err.response)
      });
    }

    function nominal(number) {
      operational.value.nominal = number.toString().replaceAll('.', '');
      operationalValue.value.nominal = MyUtil.numberWithDots(operational.value.nominal);
    }

    function quantity(number) {
      operational.value.quantity = number.toString().replaceAll('.', '');
      operationalValue.value.quantity = MyUtil.numberWithDots(operational.value.quantity);
    }

    return {
      operational,
      itemTypes,
      submitOperationalProperties,
      operationalValue,
      MyUtil,
      togglePanelRecording,
      submitOperational,
      nominal,
      quantity,
    }

  },

}
</script>

<style scoped>

</style>
