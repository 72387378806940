<template>
  <div class="py-6 px-4 bg-indigo-700 sm:px-6">
    <div class="flex items-center justify-between">
      <DialogTitle class="text-lg font-medium text-white">
        {{ properties['title'] }}
      </DialogTitle>
      <div class="ml-3 h-7 flex items-center">
        <button type="button" class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="togglePanelRecording">
          <span class="sr-only">Close panel</span>
          <XIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
    <div class="mt-1">
      <p class="text-sm text-indigo-300">
        {{ properties['description'] }}
      </p>
    </div>
  </div>
</template>

<script>

import { DialogTitle} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {

  name: "SlideOverHead",

  props: [
    'properties'
  ],

  components: {
    DialogTitle,
    XIcon
  },

  setup(props, {emit}) {
    function togglePanelRecording() {
      emit('togglePanelRecording');
    }

    return {
      togglePanelRecording
    }
  }

}
</script>

<style scoped>

</style>
