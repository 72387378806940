<template>

  <!--  Header-->
  <div class="bg-white shadow">
    <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
      <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
        <div class="flex-1 min-w-0 text-xl font-bold">
          Memasukan Pullet
        </div>
      </div>
    </div>
  </div>


  <!-- Forms -->
  <form @submit.prevent="addToHenHouse">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-white shadow mt-8">
      <div class="md:w-1/2 w-full">

        <div>
          <label for="pullet-type" class="block text-sm font-medium text-gray-700 px-2">Tanggal Masuk Kandang</label>
          <input v-model="pullet.date_in" type="date" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
        </div>

        <div class="mt-4">
          <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Pemasok Pullet</label>
          <input v-model="pullet['supplier']" type="text" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" placeholder="PT Ayam Mandiri" required>
        </div>

        <div class="mt-4">
          <label for="pullet-type" class="block text-sm font-medium text-gray-700 px-2">Tipe Ayam</label>
          <select v-model="pullet['hen_type_id']" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            <option value="0" disabled selected>Pilih Tipe Ayam</option>
            <option v-for="(henType,index) in henTypes['hen']" :key="index" :value="henType['id']">{{henType['name']}}</option>
          </select>
        </div>

        <div class="mt-4">
          <label for="pullet-type" class="block text-sm font-medium text-gray-700 px-2">Jenis Pakan</label>
          <select v-model="pullet['feed_type']" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            <option value="0" disabled selected>Pilih Jenis Pakan</option>
            <option v-for="(feedType,index) in henTypes['feed']" :key="index" :value="feedType">{{feedType}}</option>
          </select>
        </div>

        <div class="flex mt-4">
          <div class="mr-2">
            <label for="price" class="block text-sm font-medium text-gray-700 px-2">Harga 1 Ekor</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500">
                Rp
              </div>
              <input :value="pullet.priceWithDots" @input="price($event.target.value)" type="text" autocomplete="off" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-9 sm:text-sm border-gray-300 rounded-md" placeholder="59.000" />
            </div>
          </div>

          <div class="mr-2">
            <label for="start_age" class="block text-sm font-medium text-gray-700">Usia (Hari)</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input v-model="pullet['start_age']" type="number" autocomplete="off" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md" placeholder="91" />
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-500">
                Hari
              </div>
            </div>
          </div>

          <div>
            <label for="start_population" class="block text-sm font-medium text-gray-700">Jumlah</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input :value="pullet['startPopulationWithDots']" @input="startPopulation($event.target.value)" type="text" autocomplete="off" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md" placeholder="1.000" />
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-500">
                Ekor
              </div>
            </div>
          </div>
        </div>

        <table class="mt-4 text-sm text-gray-600">
          <tr>
            <td class="px-2 py-1">Total Harga</td>
            <td class="px-2 py-1">Rp {{ Util.numberWithDots(pullet['price'] * pullet['start_population'] ?? 0) }}</td>
          </tr>
          <tr>
            <td class="px-2 py-1">Usia</td>
            <td class="px-2 py-1">{{ pullet['start_age'] }} Hari ({{ Util.dayToWeek(pullet['start_age']) }} Minggu)</td>
          </tr>
        </table>

        <div class="mt-4">
          <SubmitButton name="Masukan ke Kandang"/>
        </div>

      </div>
    </div>
  </form>

</template>

<script>

import SubmitButton from "@/components/SubmitButton";
import {ref} from "@vue/runtime-core";
import Util from "../../util/MyUtil.js"
import axios from "axios";
import router from "@/router";
import MyUtil from "@/util/MyUtil";

export default {

  components: {
    SubmitButton,
  },

  name: "CreatePullet",

  setup() {


    let pullet = ref({
      date_in: Util.dateNow(),
      hen_type_id: 0,
      supplier: '',
      feed_type: '',
      price: '',
      priceWithDots: '',
      start_age: '',
      start_population: '',
      startPopulationWithDots: '',
    });

    function price(number) {
      pullet.value.price = number.toString().replaceAll('.', '');
      pullet.value.priceWithDots = MyUtil.numberWithDots(pullet.value.price);
    }

    function startPopulation(number) {
      pullet.value.start_population = number.toString().replaceAll('.', '');
      pullet.value.startPopulationWithDots = MyUtil.numberWithDots(pullet.value.start_population);
    }

    function addToHenHouse() {
      axios.get('/sanctum/csrf-cookie');
      axios.post('/api/hen-houses/'+  router.currentRoute.value.params.id +'/pullets', pullet.value).then(()=> {
        router.replace('/hen-houses/' + router.currentRoute.value.params.id);
      }).catch((err)=> {
        console.log(err.response);
      });
    }

    let henTypes = ref({});

    let feed_type = ref({});

    //Get Hen Type
    axios.get('/api/hen-houses/pullets/type').then((response)=>{
      henTypes.value = response.data.data;
    });

    return {
      pullet,
      Util,
      addToHenHouse,
      henTypes,
      feed_type,
      price,
      startPopulation
    }

  }

}
</script>

<style scoped>

</style>
