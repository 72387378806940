<template>
  <SlideOver :properties="properties">
    <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl" @submit.prevent="submitTreatmentCost">
      <div class="flex-1 h-0 overflow-y-auto">
        <SlideOverHead :properties="properties" @togglePanelRecording="togglePanelTreatment" />
        <div class="flex-1 flex flex-col justify-between px-4">
          <div class="mt-4">
            <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Tanggal Pembelian</label>
            <div class="mt-1">
              <input type="date" v-model="treatment.date" required class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="mt-4">
            <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Nama Barang</label>
            <input v-model="treatment.name" type="text" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" placeholder="Nama Barang" required>
          </div>
          <div class="mt-4">
            <label for="supplier" class="block text-sm font-medium text-gray-700 px-2">Jenis Barang</label>
            <select v-model="treatment.type" required class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
              <option value="" disabled>--pilih--</option>
              <option v-for="item in itemTypes" :key="item">{{ item }}</option>
            </select>
          </div>

          <div class="flex">
            <div class="mt-4">
              <label class="block text-sm font-medium text-gray-700">Harga Per Barang</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  Rp
                </div>
                <input :value="treatmentValue.nominal" @input="pricePerItem($event.target.value)" required type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>
            <div class="mt-4 ml-1">
              <label class="block text-sm font-medium text-gray-700">Ongkos Kirim</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  Rp
                </div>
                <input :value="treatmentValue.shipping_cost" @input="shippingCost($event.target.value)" required type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>
          </div>

          <div class="flex">
            <div class="mt-6 mr-2">
              <div>
                <label class="block text-sm font-medium text-gray-700">Jumlah Barang</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    X
                  </div>
                  <input :value="treatmentValue.quantity" @input="totalItem($event.target.value)" required type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" />
                </div>
              </div>
            </div>
            <div class="mt-6">
              <label for="account-number" class="block text-sm font-medium text-gray-700">Nama Satuan</label>
              <div class="mt-1 rounded-md shadow-sm w-32">
                <select v-model="treatment.unit" required class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                  <option value="" disabled>--pilih--</option>
                  <option v-for="unit in unitTypes" :key="unit">{{ unit }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 pl-6 text-sm text-gray-500">
          Total {{ MyUtil.toRupiah(treatment.nominal * treatment.quantity)}}
        </div>
      </div>
      <SlideOverSubmitForm :submit-properties="submitProperties" @togglePanelRecording="togglePanelTreatment" />
    </form>
  </SlideOver>
</template>

<script>

import SlideOver from "@/components/SlideOver";
import {ref} from "@vue/runtime-core";
import MyUtil from "@/util/MyUtil";
import SlideOverHead from "@/components/SlideOverHead";
import SlideOverSubmitForm from "@/components/SlideOverSubmitForm";
import router from "@/router";
import axios from "axios";

export default {

  name: "CreateTreatment",

  props: [
      'properties'
  ],

  components: {
    SlideOver,
    SlideOverHead,
    SlideOverSubmitForm
  },

  setup(props, {emit}) {

    let treatment = ref({
      name: '',
      type: '',
      nominal: '',
      quantity: '',
      shipping_cost: '',
      unit: '',
      date: MyUtil.dateNow()
    });

    let submitProperties = ref({
      name: 'Simpan',
      loading: false,
    })

    let unitTypes = ref([
      'Sak',
      'Botol',
      'Buah',
      'Box',
    ]);

    let itemTypes = ref([
      'Premix',
      'Vitamin',
      'Vaksin',
      'Obat',
      'Alat',
      'Lain-lain',
    ])

    let treatmentValue = ref({
      nominal: '',
      quantity: '',
      shipping_cost: ''
    });

    function pricePerItem(number) {
      treatment.value.nominal = number.toString().replaceAll('.', '');
      treatmentValue.value.nominal = MyUtil.numberWithDots(treatment.value.nominal);
    }

    function shippingCost(number) {
      treatment.value.shipping_cost = number.toString().replaceAll('.', '');
      treatmentValue.value.shipping_cost = MyUtil.numberWithDots(treatment.value.shipping_cost);
    }

    function totalItem(number) {
      treatment.value.quantity = number.toString().replaceAll('.', '');
      treatmentValue.value.quantity = MyUtil.numberWithDots(treatment.value.quantity);
    }

    let url = `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.properties.pulletId}/treatment-costs`;

    function submitTreatmentCost() {
      submitProperties.value.loading = true;
      axios.get('/sanctum/csrf-cookie');
      axios.post(url, treatment.value).then(() => {
        submitProperties.value.loading = false;
        togglePanelTreatment();
        emit('getTreatmentCost', true);
      }).catch(err => {
        console.log(err.response)
      });
    }

    function togglePanelTreatment() {

      emit('togglePanelRecording');

      treatment.value.name = '';
      treatment.value.type = '';
      treatment.value.nominal = '';
      treatment.value.quantity = '';
      treatment.value.shipping_cost = '';
      treatment.value.unit = '';
      treatment.value.date = MyUtil.dateNow()

      treatmentValue.value.shipping_cost = "";
      treatmentValue.value.quantity = "";
      treatmentValue.value.nominal = "";

    }

    return {
      togglePanelTreatment,
      treatment,
      treatmentValue,
      totalItem,
      pricePerItem,
      MyUtil,
      submitTreatmentCost,
      unitTypes,
      itemTypes,
      shippingCost,
      submitProperties
    }

  }

}

</script>
