<template>
  <div class="animate-pulse flex space-x-4">
    <div class="flex-1 space-y-4 py-1">
      <div class="h-3 bg-blue-300 rounded w-full"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingBarOneLine"
}
</script>

<style scoped>

</style>
