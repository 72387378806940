<template>
  <div>

    <div class="text-sm bg-white mt-3 px-3 py-3 text-gray-500">
      <table>
        <tr>
          <td class="px-3">Uang Masuk</td>
          <td class="w-20"><LoadingBarOneLine v-if="totalMoney.credit === ''"/> {{ totalMoney.credit }}</td>
        </tr>
        <tr>
          <td class="px-3">Uang Keluar</td>
          <td class="w-24"><LoadingBarOneLine v-if="totalMoney.debit === ''" /> {{ totalMoney.debit }}</td>
        </tr>
      </table>
    </div>

    <LoadingElipsis v-if="properties.loadingCashFlow" class="py-6"/>
    <div v-else>
      <!-- Activity list (smallest breakpoint only) -->
      <div class="shadow sm:hidden" v-if="cashFlows">
        <ul class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
          <li v-for="transaction in cashFlows" :key="transaction">
            <a  class="block px-4 py-4 bg-white hover:bg-gray-50">
              <span class="flex items-center space-x-4">
                <span class="flex-1 flex space-x-2 truncate">
                  <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span class="flex flex-col text-gray-500 text-sm truncate">
                    <span class="truncate">{{ transaction['title'] }}</span>
                    <span>
                      <span :class="[statusStyles[transaction['type']], 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                        {{ transaction['type'] }}
                      </span>
                    </span>
                    <span>
                      <span class="text-gray-900 font-medium">{{ transaction['total'] }}</span>
                    </span>
                    <time :datetime="transaction['created_at']">{{ transaction['created_at'] }}</time>
                  </span>
                </span>
                <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </a>
          </li>
        </ul>
      </div>

      <!-- Activity table (small breakpoint and up) -->
      <div class="hidden sm:block">
        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex flex-col mt-2">
            <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                  <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Transaction
                  </th>
                  <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                    Status
                  </th>
                  <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="transaction in cashFlows" :key="transaction['id']" class="bg-white">
                  <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div class="flex">
                      <a class="group inline-flex space-x-2 truncate text-sm">
                        <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                        <p class="text-gray-500 truncate group-hover:text-gray-900">
                          {{ transaction['title'] }}
                        </p>
                      </a>
                    </div>
                  </td>
                  <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                    <span class="text-gray-900 font-medium">{{ transaction['total'] }} </span>
                  </td>
                  <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                    <span :class="[statusStyles[transaction['type']], 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                      {{ transaction['type'] }}
                    </span>
                  </td>
                  <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                    <time :datetime="transaction['created_at']">{{ transaction['created_at'] }}</time>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div ref="trigger"/>

  </div>
</template>

<script>

import {
  CashIcon,
  ChevronRightIcon,
} from '@heroicons/vue/solid'
import axios from "axios";
import {onMounted, ref} from "@vue/runtime-core";
import Number from "@/util/MyUtil";
import router from "@/router";
import MyUtil from "@/util/MyUtil";
import LoadingBarOneLine from "@/components/LoadingBarOneLine";
import LoadingElipsis from "@/components/LoadingElipsis";

const transactions = [
  {
    id: 1,
    name: 'Jagung dari Acep',
    href: '#',
    quantity: '1.700',
    currency: 'Kg',
    status: 'success',
    date: 'July 11, 2020 (1 Bulan Yang Lalu)',
    datetime: '2020-07-11',
  },
];

const statusStyles = {
  credit: 'bg-green-100 text-green-800',
  cancel: 'bg-red-100 text-red-800',
  debit: 'bg-yellow-100 text-yellow-800',
}

export default {

  name: "CashFlow",

  components: {
    LoadingElipsis,
    LoadingBarOneLine,
    ChevronRightIcon,
    CashIcon
  },

  props: [
    'pulletId'
  ],

  setup(props) {

    let cashFlows = ref([]);

    let trigger = ref();

    const properties = ref({
      loadingCashFlow: true
    });

    const totalMoney = ref({
      credit: '',
      debit: ''
    });

    let getCashFlowOptions = ref({
      url: `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/cash-flows`,
      next_page_url: '',
      observer: true,
      setObserver: setObserver,
      refresh: true
    });

    function getCashFlows() {
      axios.get(getCashFlowOptions.value.next_page_url ? getCashFlowOptions.value.next_page_url : getCashFlowOptions.value.url).then((response) => {
        if (response.data.code) {
          response.data.data.data.data.map((data)=>{
            data['total'] = Number.toRupiah(data['total']);
            data['created_at'] = Number.toLocaleDateString(data['created_at'])
          });

          // Convert data from object to array
          let toArray = Object.keys(response.data.data.data.data).map((key) => response.data.data.data.data[key])

          totalMoney.value.debit = MyUtil.toRupiah(response.data.data.total.debit)
          totalMoney.value.credit = MyUtil.toRupiah(response.data.data.total.credit)

          // Push data to array collection
          toArray.forEach((res)=> {
            cashFlows.value.push(res);
          })

          // set next_page_url for infinite scroll
          getCashFlowOptions.value.next_page_url = response.data.data.data.next_page_url ?? ''

          // stop observer if latest page viewed
          if (response.data.data.data.next_page_url === null) {
            getCashFlowOptions.value.observer = false;
          }

          // Disable loading
          properties.value.loadingCashFlow = false;

        }
      });
    }

    let setObserver = onMounted(()=> {
      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (getCashFlowOptions.value.observer) {
              getCashFlows()
            }
          }
        })
      }, {
        root: null,
        threshold: 0
      });
      observer.observe(trigger.value);
    })

    return {
      statusStyles,
      transactions,
      cashFlows,
      trigger,
      totalMoney,
      properties
    }
  },

}
</script>

<style scoped>

</style>
