<template>
  <Modal :show="modalProperties.showPayment" @togglePanelBill="togglePanelBill">
    <form @submit.prevent="payBill">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div class="mt-3 sm:mt-0 sm:ml-4">
            <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">
              Membayar Tagihan
            </DialogTitle>
            <div class="mt-2">
              <table class="text-gray-500 text-sm">
                <tr>
                  <td>Tanggal Beli</td>
                  <td>{{ MyUtil.toLocaleDateString(modalProperties.selling['date_bill']) }}</td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td>{{ modalProperties.selling.name }}</td>
                </tr>
                <tr>
                  <td>Total tagihan</td>
                  <td>{{ MyUtil.toRupiah(modalProperties.selling['total_bill']) }}</td>
                </tr>
                <tr>
                  <td class="font-bold">Sisa Tagihan</td>
                  <td class="font-bold">{{ MyUtil.toRupiah(modalProperties.selling['rest_of_the_bill']) }}</td>
                </tr>
                <tr>
                  <td class="pt-2 text-black">Riwayat Pembayaran</td>
                </tr>
                <tr v-for="history in modalProperties.selling['cash_flows']" :key="history">
                  <td>{{ MyUtil.toLocaleDateString(history['date']) }}</td>
                  <td>{{ MyUtil.toRupiah(history['nominal']) }}</td>
                </tr>
                <tr v-if="modalProperties.selling['cash_flows'].length === 0">
                  <td>--</td>
                  <td>--</td>
                </tr>
              </table>
              <div class="mt-4" v-if="modalProperties.selling['rest_of_the_bill'] !== 0">
                <label class="block text-sm font-medium text-gray-700">Nominal Pembayaran</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="text-gray-500 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    Rp
                  </div>
                  <input :value="payValue.nominal" @input="nominal($event.target.value)" required type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 sm:text-sm border-gray-300 rounded-md" placeholder="0" />
                </div>
                <div class="mt-2 pl-2 text-sm" :class="pay.disabled ? 'text-red-500' : 'text-gray-500'">
                  {{ pay.message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div v-if="modalProperties.selling['rest_of_the_bill'] === 0" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
          Sudah Lunas
        </div>
        <SubmitButton v-else :disabled="pay.disabled" name="Bayar" :is-loading="pay.loading" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" />
        <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="togglePanelBill" ref="cancelButtonRef">
          Tutup
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import { DialogTitle } from '@headlessui/vue'
import { ExclamationIcon } from '@heroicons/vue/outline'
import MyUtil from "@/util/MyUtil";
import SubmitButton from "@/components/SubmitButton";
import {ref} from "@vue/runtime-core";
import axios from "axios";
import Modal from "@/components/Modal";

export default {

  components: {
    SubmitButton,
    DialogTitle,
    ExclamationIcon,
    Modal
  },

  props: [
      'modalProperties'
  ],

  setup(props, {emit}) {

    let pay = ref({
      nominal: '',
      loading: false,
      disabled: false,
      payOff: false,
      message: 'Masukan Nominal Bayar'
    });

    let payValue = ref({
      nominal: ''
    });

    function togglePanelBill() {
      emit('togglePanelBill');
      pay.value.nominal = '';
      payValue.value.nominal = '';
      pay.value.loading = false;
      pay.value.disabled = false;
      pay.value.payOff = false;
      pay.value.message = 'Masukan Nominal Bayar'
    }

    function nominal(number) {
      pay.value.nominal = number.toString().replaceAll('.', '');
      payValue.value.nominal = MyUtil.numberWithDots(pay.value.nominal);
      let payment = props.modalProperties.selling['rest_of_the_bill'] - parseInt(pay.value.nominal);
      if (payment > 0) {
        pay.value.disabled = false;
        pay.value.payOff = false;
        pay.value.message = "Sisa " + MyUtil.toRupiah(payment)
      } else if(payment === 0) {
        pay.value.disabled = false;
        pay.value.payOff = true;
        pay.value.message = "Lunas"
      } else {
        pay.value.payOff = false;
        pay.value.disabled = true;
        pay.value.message = "Nominal Salah"
      }
    }

    function payBill() {
      pay.value.loading = true;
      axios.get('/sanctum/csrf-cookie');
      axios.post(props.modalProperties.url, {
        'date' : MyUtil.dateNow(),
        'nominal' : pay.value.nominal
      }).then(() => {
        emit('getSelling', true);
        pay.value.loading = false;
        togglePanelBill()
      }).catch(err => {
        console.log(err.response)
      });
    }

    return {
      open,
      togglePanelBill,
      MyUtil,
      payBill,
      pay,
      payValue,
      nominal
    }
  },
}
</script>
