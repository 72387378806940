<template>
  <div>
    <CreateRecording
        :properties="createRecording"
        @togglePanelRecording="togglePanelRecording"
        @insertRecordingAvailable="insertRecordingAvailable"
        @getRecording="getRecording"
        :pulletId="pulletId ? pulletId : null"
    ></CreateRecording>

    <div class="py-2 md:px-8 px-3 bg-white">

      <div class="flex justify-end">
        <button @click="togglePanelRecording" v-if="createRecording.availableInsert" type="button" class="mb-6 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
          Buat Record
        </button>
        <div v-else class="mb-6 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
          {{ createRecording['availableInsertMsg'] }}
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                  <th v-for="thead in thead" :key="thead.name" scope="col" class="px-6 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                    {{ thead.name }}
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="recording in recordings" :key="recording['id']">
                    <!-- Date -->
                    <td class="px-3 py-1 whitespace-nowrap text-sm">
                      {{ MyUtil.toLocaleDateString(recording['date']) }}
                    </td>
                    <!-- Age -->
                    <td class="px-3 py-1 whitespace-nowrap">
                      <div class="text-sm text-gray-900">{{ recording['age']['week'] }} Minggu</div>
                      <div class="text-sm text-gray-500">{{ recording['age']['day'] }} Hari</div>
                    </td>
                    <!-- Population -->
                    <td class="bg-green-50 px-3 py-1 whitespace-nowrap text-center">
                      <table>
                        <tr>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Kematian</div>
                            <div class="text-sm text-gray-900">{{ recording['population']['mortality_cumulative'] }}</div>
                            <div class="text-sm text-gray-500">{{ recording['population']['mortality_cumulative_percentage'] }}%</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Populasi Alhir</div>
                            <div class="text-sm text-gray-900">{{ recording['population']['final'] }}</div>
                            <div class="text-sm text-gray-500">{{ recording['population']['final_percentage'] }}%</div>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <!-- Egg Productions -->
                    <td class="px-3 py-1 whitespace-nowrap text-center">
                      <table>
                        <tr>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Utuh</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_production']['whole'] }}</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Putih</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_production']['white'] }}</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Retak</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_production']['cracked'] }}</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Total</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_production']['total'] }}</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">HD%</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_production']['hen_day_percentage'] }}%</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">HH%</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_production']['hen_house_percentage'] }}%</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Total Butir per HH</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_production']['total_egg_per_hen_house'] }} butir</div>
                          </td>

                        </tr>
                      </table>
                    </td>
                    <!-- Egg Masses -->
                    <td class="bg-green-50 px-3 py-1 whitespace-nowrap text-center">
                      <table>
                        <tr>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Harian</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_mass']['day'] }}</div>
                            <div class="text-sm text-gray-500">Kg</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Kumulatif</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_mass']['cumulative'] }}</div>
                            <div class="text-sm text-gray-500">Kg</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">g/butir</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_mass']['gr_per_egg'] }}</div>
                            <div class="text-sm text-gray-500">Gram</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">g/HD</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_mass']['gr_per_hen_day'] }}</div>
                            <div class="text-sm text-gray-500">Gram</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Kg/HH</div>
                            <div class="text-sm text-gray-900">{{ recording['egg_mass']['kg_per_hen_house'] }}</div>
                            <div class="text-sm text-gray-500">Kg</div>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <!-- Feed Intake -->
                    <td class="px-3 py-1 whitespace-nowrap text-center">
                      <table>
                        <tr>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Harian</div>
                            <div class="text-sm text-gray-900">{{ recording['feed_intake']['mass'] }}</div>
                            <div class="text-sm text-gray-500">Kg</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Kumulatif</div>
                            <div class="text-sm text-gray-900">{{ recording['feed_intake']['cumulative'] }}</div>
                            <div class="text-sm text-gray-500">Kg</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">g/Ekor</div>
                            <div class="text-sm text-gray-900">{{ recording['feed_intake']['gr_per_hen'] }}</div>
                            <div class="text-sm text-gray-500">Gram</div>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <!-- Feed Consumption Ratio -->
                    <td class="bg-green-50 px-3 py-1 whitespace-nowrap text-center">
                      <table>
                        <tr>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Harian</div>
                            <div class="text-sm text-gray-900">{{ recording['feed_composition_ratio']['daily'] }}</div>
                          </td>
                          <td class="px-3 py-1 whitespace-nowrap">
                            <div class="text-sm text-gray-500">Kumulatif</div>
                            <div class="text-sm text-gray-900">{{ recording['feed_composition_ratio']['cumulative'] }}</div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr ref="trigger" />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import {ref,onMounted} from "@vue/runtime-core";
import router from "@/router";
import CreateRecording from "@/components/CreateRecording";
import MyUtil from "@/util/MyUtil";

const thead = [
  {name: 'Tanggal'},
  {name: 'Umur'},
  {name: 'Populasi'},
  {name: 'Produksi Telur (Butir)'},
  {name: 'Berat Telur'},
  {name: 'Konsumsi Pakan'},
  {name: 'FCR'},
]

export default {

  name: "Recording",

  components: {
    CreateRecording
  },

  props: [
      'pulletId'
  ],

  setup(props) {

    let recordings = ref([]);
    let createRecording = ref({
      show: false,
      lastPopulation: '',
      availableInsert: false,
      availableInsertMsg: 'Loading'
    });

    let getRecordingOptions = ref({
      url: `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/recordings`,
      next_page_url: '',
      observer: true
    });

    const trigger = ref();

    let observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (getRecordingOptions.value.observer) {
            getRecording()
          } else {
            observer.unobserve(trigger.value)
          }
        }
      })}, {
      root: null,
      threshold: 0
    });

    onMounted(()=> {
      observer.observe(trigger.value);
    })

    function getRecording(refresh = false) {
      axios.get(getRecordingOptions.value.url + (refresh ? '' : getRecordingOptions.value.next_page_url))
          .then((response) => {

            // Convert data from object to array
            let toArray = Object.keys(response.data.data.data).map((key) => response.data.data.data[key])

            // if refresh recoding change to empty for refresh data newest
            refresh ? recordings.value = [] : '';

            // Push data to array collection
            toArray.forEach((res)=> {
              recordings.value.push(res);
            })

            // set last population
            createRecording.value.lastPopulation    = toArray[toArray.length-1]['population']['final']
            // set next_page_url for infinite scroll
            getRecordingOptions.value.next_page_url = response.data.data.next_page_url ?? ''

            // stop observer if latest page viewed
            if (response.data.data.next_page_url === null) {
              getRecordingOptions.value.observer = false;
            }
            observer.observe(trigger.value);

        }).catch((err)=>{
          console.log(err.response);
        })

    }

    function togglePanelRecording() {
      createRecording.value.show = !createRecording.value.show;
    }

    function insertRecordingAvailable(available) {
      if (available) {
        createRecording.value.availableInsertMsg = 'Recording Beres';
        createRecording.value.availableInsert = false;
      } else {
        createRecording.value.availableInsert = true;
        createRecording.value.availableInsertMsg = 'Buat Recording';
      }
    }

    return {
      thead,
      togglePanelRecording,
      recordings,
      CreateRecording,
      createRecording,
      insertRecordingAvailable,
      MyUtil,
      trigger,
      getRecording
    }

  }

}
</script>

<style scoped>

</style>
