<template>

  <CreateSelling
      :properties="properties"
      :pulletId="pulletId ? pulletId : null"
      @togglePanelRecording="togglePanelRecording"
      @getSelling="getSelling"
      v-model:id="sellingData.id"
      v-model:date="sellingData.date"
      v-model:name="sellingData.name"
      v-model:nominal="sellingData.nominal"
      v-model:shipping_cost="sellingData.shipping_cost"
      v-model:quantity="sellingData.quantity"
      v-model:status="sellingData.status"
      v-model:nominal_with_dots="sellingData.nominal_with_dots"
      v-model:shipping_cost_with_dots="sellingData.shipping_cost_with_dots"
      v-model:quantity_with_dots="sellingData.quantity_with_dots"
  />

  <ModalPayment
      :modalProperties="modalProperties"
      @togglePanelBill="togglePanelBill"
      @getSelling="getSelling"
  />

  <ModalDeleteSelling
      v-model:showDelete="modalProperties.showDelete"
      :delete-url="modalProperties.url"
      :selling-data="sellingData"
      @getSelling="getSelling"
  />

  <div class="py-2 md:px-8 px-3 bg-white">

    <LoadingElipsis v-if="properties.loadingSelling"/>

    <div v-else>
      <div v-if="sellings.length > 0">

        <div class="flex justify-end">
          <button @click="togglePanelRecording" type="button" class="mb-6 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
            Buat Penjualan
          </button>
        </div>

        <h2 class="max-w-6xl mx-auto mt-4 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
          Daftar Penjualan
        </h2>

        <!-- Activity list (smallest breakpoint only) -->
        <div class="shadow sm:hidden">
          <ul class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
            <li v-for="(selling, index) in sellings" :key="selling['id']">
              <Smallitem
                  :index="index"
                  :properties="properties"
                  :item-id="selling['id']"
                  v-model:payLoading="modalProperties.loading"
                  v-model:payLoadingId="modalProperties.loadingId"
                  v-model:showDelete="modalProperties.showDelete"
                  v-model:deleteId="modalProperties.deleteId"
                  v-model:loadingDelete="modalProperties.loadingDelete"
                  :actionEdit="true"
                  :actionPay="selling['status'] === 'Tagihan'"
                  :actionDelete="true"
                  @togglePanelBill="togglePanelBill(selling['id'])"
                  @togglePanelRecording="togglePanelRecording"
                  @toggleModalDelete="toggleModalDelete(selling['id'])"
              >
                <span class="flex items-center space-x-4">
              <span class="flex-1 flex space-x-2 truncate">
                <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span class="flex flex-col text-gray-500 text-sm truncate">
                  <span class="truncate">{{ selling.name }}</span>
                  <span>
                    <span class="text-gray-900 font-medium">{{ MyUtil.toRupiah(selling['nominal'] * selling['quantity'] + selling['shipping_cost']) }}</span>
                  </span>
                  <span>{{ MyUtil.toRupiah(selling['nominal']) }} X {{ selling['quantity'] }}</span>
                  <span v-if="selling['shipping_cost'] !== 0">Biaya kirim : {{ MyUtil.toRupiah(selling['shipping_cost']) }}</span>
                  <time>{{ MyUtil.toLocaleDateString(selling['date']) }}</time>
                  <span class="flex">
                    <span :class="statusStyles[selling['status']]" class="inline-block">{{ selling['status'] }}</span>
                  </span>
                </span>
              </span>
              <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
              </Smallitem>
            </li>
          </ul>
        </div>

        <!-- Activity table (small breakpoint and up) -->
        <div class="hidden sm:block">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col mt-2">
              <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                  <tr>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Transaction
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                      Status
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="transaction in transactions" :key="transaction.id" class="bg-white">
                    <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <div class="flex">
                        <a :href="transaction.href" class="group inline-flex space-x-2 truncate text-sm">
                          <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          <p class="text-gray-500 truncate group-hover:text-gray-900">
                            {{ transaction.name }}
                          </p>
                        </a>
                      </div>
                    </td>
                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                      <span class="text-gray-900 font-medium">{{ transaction.amount }} </span>
                      {{ transaction.currency }}
                    </td>
                    <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                  <span :class="[statusStyles[transaction.status], 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                    {{ transaction.status }}
                  </span>
                    </td>
                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                      <time :datetime="transaction.datetime">{{ transaction.date }}</time>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!-- Pagination -->
                <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                  <div class="hidden sm:block">
                    <p class="text-sm text-gray-700">
                      Showing
                      {{ ' ' }}
                      <span class="font-medium">1</span>
                      {{ ' ' }}
                      to
                      {{ ' ' }}
                      <span class="font-medium">10</span>
                      {{ ' ' }}
                      of
                      {{ ' ' }}
                      <span class="font-medium">20</span>
                      {{ ' ' }}
                      results
                    </p>
                  </div>
                  <div class="flex-1 flex justify-between sm:justify-end">
                    <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      Previous
                    </a>
                    <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      Next
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <EmptyProject
            @togglePanelRecording="togglePanelRecording"
            btn-title="Buat Penjualan"
            description="Catat penjualan pertama mu di sini"
            head-title="Belum ada penjualan"
        />
      </div>
    </div>


    <div ref="trigger"/>

  </div>
<!--  <div class="fixed inset-x-0 bottom-0 bg-red-500 flex justify-center py-3">-->
<!--    <button class="flex justify-center border border-transparent"-->
<!--            type="submit">-->
<!--      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">-->
<!--        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>-->
<!--        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
<!--      </svg>-->
<!--    </button>-->
<!--  </div>-->

</template>

<script>

import CreateSelling from "@/components/CreateSelling";
import {onMounted, ref} from "@vue/runtime-core";
import MyUtil from "@/util/MyUtil";
import ModalPayment from "@/components/ModalPayment";
import ModalDeleteSelling from "@/components/ModalDeleteSelling";
import Smallitem from "@/components/Smallitem";
import EmptyProject from "@/components/EmptyProject";

import {
  CashIcon,
  ChevronRightIcon,
} from '@heroicons/vue/solid'
import router from "@/router";
import axios from "axios";
import LoadingElipsis from "@/components/LoadingElipsis";

const transactions = [
  {
    id: 1,
    name: 'Payment to Molly Sanders',
    href: '#',
    amount: '$20,000',
    currency: 'USD',
    status: 'success',
    date: 'July 11, 2020',
    datetime: '2020-07-11',
  },
  // More transactions...
]

const statusStyles = {
  Lunas: 'bg-green-100 text-green-800 px-3 py-1 rounded',
  Tagihan: 'bg-yellow-100 text-yellow-800 px-3 py-1 rounded',
}

export default {

  name: "Selling",

  components: {
    LoadingElipsis,
    EmptyProject,
    CashIcon,
    ChevronRightIcon,
    CreateSelling,
    ModalPayment,
    Smallitem,
    ModalDeleteSelling,
  },

  props: [
    'pulletId'
  ],

  setup(props) {

    let properties = ref({
      show: false,
      pulletId: props.pulletId,
      title: 'Buat Penjualan',
      url: '',
      loadingSelling: true,
    });

    let modalProperties = ref({
      showPayment: false,
      showDelete: false,
      pulletId: props.pulletId,
      sellingId: '',
      selling: '',
      loading: false,
      loadingId: null,
      url: '',
      deleteId: null,
      loadingDelete: false,
    });

    let sellingData = ref({
      id: null,
      date: MyUtil.dateNow(),
      name: '',
      nominal: null,
      shipping_cost: null,
      quantity: null,
      status: '',
      nominal_with_dots: '',
      shipping_cost_with_dots: '',
      quantity_with_dots: ''
    })

    let sellings = ref([]);

    let trigger = ref();

    let getSellingOptions = ref({
      url: `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/sellings`,
      next_page_url: `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/sellings`,
      observer: true,
      setObserver: setObserver,
      refresh: true
    });

    let setObserver = onMounted(()=> {
      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (getSellingOptions.value.observer) {
              getSelling()
            }
          }
        })
      }, {
        root: null,
        threshold: 0
      });
      observer.observe(trigger.value);
    })

    function togglePanelRecording(responseFeedData) {
      if(typeof responseFeedData == 'object') {
        if (Number.isInteger(responseFeedData.id)) {
          // fill data form edit
          sellingData.value = responseFeedData;
          sellingData.value.nominal_with_dots = MyUtil.numberWithDots(responseFeedData.nominal)
          sellingData.value.shipping_cost_with_dots = MyUtil.numberWithDots(responseFeedData.shipping_cost)
          sellingData.value.quantity_with_dots = MyUtil.numberWithDots(responseFeedData.quantity)

          properties.value.url = getSellingOptions.value.url + '/' + responseFeedData.id;
          properties.value.show = true
        } else {
          properties.value.show = !properties.value.show;
          properties.value.url = getSellingOptions.value.url;
        }
      } else {
        properties.value.show = !properties.value.show;
        properties.value.url = getSellingOptions.value.url;
      }

      if (!properties.value.show) {

        // Reset form data
        sellingData.value.id = null;
        sellingData.value.date = MyUtil.dateNow();
        sellingData.value.name = "";
        sellingData.value.status = "";
        sellingData.value.nominal = null;
        sellingData.value.shipping_cost = null;
        sellingData.value.quantity = null;
        sellingData.value.nominal_with_dots = '';
        sellingData.value.shipping_cost_with_dots = ''
        sellingData.value.quantity_with_dots = '';
      }
    }

    function getSelling(refresh = false) {
      refresh ? properties.value.loadingSelling = true : '';
      axios.get((refresh ? getSellingOptions.value.url : getSellingOptions.value.next_page_url))
          .then((response) => {

            properties.value.loadingSelling = false;

            // Reset refresh to false when it's true
            refresh ? getSellingOptions.value.refresh = false : '';

            // Convert data from object to array
            let toArray = Object.keys(response.data.data.data).map((key) => response.data.data.data[key])

            // if refresh recoding change to empty for refresh data newest
            refresh ? sellings.value = [] : '';
            refresh ? (getSellingOptions.value.observer = true) : ''

            // Push data to array collection
            toArray.forEach((res)=> {
              sellings.value.push(res);
            })

            // set next_page_url for infinite scroll
            getSellingOptions.value.next_page_url = response.data.data.next_page_url ?? getSellingOptions.value.url

            // set url for edit single data
            properties.value.url = getSellingOptions.value.url

            // stop observer if latest page viewed
            if (response.data.data.next_page_url === null) {
              getSellingOptions.value.observer = false;
            }

          }).catch((err)=>{
            console.log(err.response);
          })
    }

    function togglePanelBill(id) {
      if (typeof id !== 'undefined') {
        modalProperties.value.loading = true;
        modalProperties.value.loadingId = id;
        axios.get(getSellingOptions.value.url + '/' + id + '/pay-bill').then((response)=>{
          modalProperties.value.loading = false;
          modalProperties.value.selling = response.data.data;
          modalProperties.value.showPayment = true;
          modalProperties.value.url = getSellingOptions.value.url + '/' + id + '/pay-bill';
        }).catch((err)=>{
          console.log(err.response);
        })
      } else {
        modalProperties.value.showPayment = false;
      }
    }

    function toggleModalDelete(sellingId) {
      modalProperties.value.url = getSellingOptions.value.url + '/' + sellingId
      axios.get(modalProperties.value.url).then((response)=>{
        modalProperties.value.showDelete = true;
        modalProperties.value.loadingDelete = false;
        sellingData.value = response.data.data
      })
    }

    function payBill() {
      console.log('masuk')
    }

    return {
      togglePanelRecording,
      properties,
      transactions,
      statusStyles,
      getSelling,
      sellings,
      MyUtil,
      payBill,
      modalProperties,
      togglePanelBill,
      trigger,
      sellingData,
      toggleModalDelete
    }

  }

}
</script>
