
import {initializeApp} from 'firebase/app'

let firebaseConfig = {
    apiKey: "AIzaSyDoEhebGrjqj6y43ciOKRyG6l75dQa8wTI",
    authDomain: "auth.bertelur.com",
    projectId: "bertelur-912f5",
    storageBucket: "bertelur-912f5.appspot.com",
    messagingSenderId: "460246354606",
    appId: "1:460246354606:web:5f6c4ab8962671c9c9fb3f",
    measurementId: "G-F38JD3B3KE"
}

initializeApp(firebaseConfig)
