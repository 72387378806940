<template>
  <ul class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden" v-if="feedStocks">
    <li v-for="feedStock in feedStocks.data.data" :key="feedStock['id']">
      <Smallitem
        :properties="properties"
        :itemId="feedStock['id']"
        @togglePanelRecording="togglePanelRecording"
        :actionEdit="true"
        :actionPay="false"
        :actionDelete="true"
      >
        <span class="flex items-center space-x-4 z-50">
          <span class="flex-1 flex space-x-2 truncate">
            <CashIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <span class="flex flex-col text-gray-500 text-sm truncate">
              <span class="truncate"><span class="text-black">{{ feedStock['feed_type'] }}</span> - {{ feedStock['supplier'] }}</span>
              <span>
                Status : <span :class="feedStocks['css']" class=" font-medium">{{ feedStocks['name'] }}</span>
              </span>
              <span>
                Beli : <span class="text-gray-900 font-medium">{{ feedStock['mass'] }}</span> Kg
              </span>
              <span>
                Sisa : <span class="text-gray-900 font-medium">{{ feedStock['mass_in_stock'] }}</span> Kg
              </span>
              <time :datetime="feedStock['date']['native']">Pembelian {{ feedStock['date']['diffForHuman'] }}</time>
            </span>
          </span>
          <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </Smallitem>
    </li>
  </ul>
</template>

<script>
import {CashIcon,ChevronRightIcon} from "@heroicons/vue/solid/index";
import {ref} from "@vue/runtime-core";
import Smallitem from "@/components/Smallitem";

export default {

  name: "FeedStock",

    props: [
      "feedStocks",
      "properties"
  ],

  components: {
    Smallitem,
    CashIcon,
    ChevronRightIcon,
  },

  setup(props, {emit}) {

    let action = ref({
      show: false,
      showId: ''
    });

    function togglePanelRecording(id) {
      emit('togglePanelRecording', id)
    }

    function showAction(id) {
      action.value.showId = id;
      action.value.show = !action.value.show;
    }

    return {
      togglePanelRecording,
      showAction,
      action
    }

  },
}
</script>
