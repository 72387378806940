<template>
  <TransitionRoot as="template" :show="properties.show" class="z-40">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden" @close="togglePanelRecording" :open="properties.show">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <form @submit.prevent="insertRecording" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        Buat Recording
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="togglePanelRecording">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-indigo-300">
                        Buat hidupmu lebih mudah dengan pencatatan setiap hari.
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <label for="email" class="block text-sm font-medium text-gray-700">Tanggal</label>
                          <div class="mt-1 relative rounded-md shadow-sm">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <select v-model="recording" required class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md">
                              <option v-for="emptyRecording in emptyRecordings" :key="emptyRecording['day']" :value="emptyRecording">
                                {{ emptyRecording['isoFormat'] }}
                              </option>
                            </select>
                          </div>
                          <div class="text-sm pl-3 mt-2 text-gray-400 flex items-center">
                              <ClockIcon class=" h-5 w-5 rounded-full mr-2"/>
                            {{recording['diffForHumans']}}
                          </div>
                        </div>
                        <div class="mt-4">
                          <label for="project-name" class="block text-sm font-medium text-gray-900">
                            Performa Telur
                          </label>
                          <div class="text-gray-500 text-sm">(Isi 0 Jika belum bertelur)</div>
                          <div class="mt-2">
                            <table>
                              <tr>
                                <td class="pr-2">
                                  <div class="text-sm font-medium text-gray-900">Utuh</div>
                                  <input v-model="recording.whole_eggs" required type="number" autocomplete="off" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
                                </td>
                                <td class="pr-2">
                                  <div class="text-sm font-medium text-gray-900">Retak</div>
                                  <input v-model="recording.cracked_eggs" required type="number" name="project-name" autocomplete="off" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
                                </td>
                                <td class="pr-2">
                                  <div class="text-sm font-medium text-gray-900">Putih</div>
                                  <input v-model="recording.egg_whites" required type="number" name="project-name" autocomplete="off" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
                                </td>
                              </tr>
                            </table>

                            <div class="text-sm font-medium text-gray-900 mt-2">Total Berat Telur</div>
                            <div class="mt-1 relative rounded-md shadow-sm w-24">
                              <input :value="recording.whole_eggs_mass" @input="wholeEggsMass" required type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md" />
                              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-500">
                                Kg
                              </div>
                            </div>

                            <div class="mt-2 text-gray-500 text-sm">
                              <table>
                                <tr>
                                  <td class="px-2">Total</td>
                                  <td class="px-2">{{
                                    isNaN(Number(recording.egg_whites) + Number(recording.whole_eggs) + Number(recording.cracked_eggs))
                                    ? 0
                                    : Number(recording.egg_whites) + Number(recording.whole_eggs) + Number(recording.cracked_eggs)
                                  }} butir</td>
                                </tr>
                                <tr>
                                  <td class="px-2">Persentase</td>
                                  <td class="px-2">{{
                                    isNaN(Number(recording.whole_eggs) / Number(properties.lastPopulation) * 100)
                                    ? 0
                                    : Util.toFixedIfNecessary(Number(recording.whole_eggs) / Number(properties.lastPopulation) * 100)
                                  }} %</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="flex">
                          <div class="mr-2">
                            <label for="description" class="block text-sm font-medium text-gray-900">
                              Pemberian Pakan
                            </label>
                            <div class="w-full">
                              <div class="mt-1 relative rounded-md shadow-sm">
                                <input v-model="recording.feed_intake" required type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md" />
                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-500">
                                  Kg
                                </div>
                              </div>
                              <div class="px-2 text-gray-500 text-sm mt-2">
                                {{
                                  isNaN(Util.toFixedIfNecessary((recording.feed_intake * 1000) / properties.lastPopulation))
                                  ? 0
                                  : Util.toFixedIfNecessary((recording.feed_intake * 1000) / properties.lastPopulation)
                                }} gr / ekor
                              </div>
                            </div>
                          </div>
                          <div>
                            <label for="description" class="block text-sm font-medium text-gray-900">
                              Kematian
                            </label>
                            <div class="w-full">
                              <div class="mt-1 relative rounded-md shadow-sm">
                                <input v-model="recording.mortality" required type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md" />
                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-500">
                                  Ekor
                                </div>
                              </div>
                              <div class="px-2 text-gray-500 text-sm mt-2">
                                Kosongkan Jika Tidak Ada
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SlideOverSubmitForm :submitProperties="submitButtonProperties" @togglePanelRecording="togglePanelRecording"/>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CalendarIcon, ClockIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/outline'
import {ref} from "@vue/runtime-core";
import axios from "axios";
import router from "@/router";
import Util from "@/util/MyUtil";
import SlideOverSubmitForm from "@/components/SlideOverSubmitForm";

const team = [
  {
    name: 'Tom Cook',
    email: 'tomcook@example.com',
    href: '#',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Whitney Francis',
    email: 'whitneyfrancis@example.com',
    href: '#',
    imageUrl:
        'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Leonard Krasner',
    email: 'leonardkrasner@example.com',
    href: '#',
    imageUrl:
        'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Floyd Miles',
    email: 'floydmiles@example.com',
    href: '#',
    imageUrl:
        'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Emily Selman',
    email: 'emilyselman@example.com',
    href: '#',
    imageUrl:
        'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
]

export default {
  components: {
    SlideOverSubmitForm,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    CalendarIcon,
    ClockIcon
  },

  props: [
    'properties',
    'pulletId',
  ],

  setup(props, {emit}) {

    let recording = ref({
      whole_eggs: 0,
      egg_whites: 0,
      cracked_eggs: 0,
      whole_eggs_mass: 0,
      feed_intake: 0,
      mortality: 0
    });

    let submitButtonProperties = ref({
      'name' : 'Simpan',
      'loading' : false,
    });

    let dateRecord = ref({});

    const emptyRecordings = ref([]);
    const url = `/api/hen-houses/${router.currentRoute.value.params.id}/pullets/${props.pulletId}/recordings/`;

    function togglePanelRecording() {
      if (!submitButtonProperties.value.loading) {
        emit('togglePanelRecording');
      }
    }

    function insertRecording() {
      submitButtonProperties.value.loading = true;
      axios.get('/sanctum/csrf-cookie');
      axios.post(url, recording.value)
          .then((response)=>{
            if (response.status === 201) {
            submitButtonProperties.value.loading = false;
              emit('getRecording', true);
              togglePanelRecording();
              emptyCheck();
            }
          }).catch((err)=>{
            submitButtonProperties.value.loading = true;
            console.log(err.response)
          })
    }

    function wholeEggsMass(event) {
      let toInteger = parseFloat(event.target.value.replace(',' , '.'));
      toInteger = isNaN(toInteger) ? null : toInteger;
      recording.value.whole_eggs_mass = toInteger;
    }

    function emptyCheck() {
      axios.get(url + 'empty-check').then((response)=>{
        if (response.data.data.items.length !== 0) {
          emptyRecordings.value = response.data.data.items;
          recording.value = response.data.data.items[0];
          emit('insertRecordingAvailable', false)
        } else {
          emit('insertRecordingAvailable', true)
        }
      })
    }

    emptyCheck()

    return {
      team,
      insertRecording,
      ClockIcon,
      recording,
      emptyRecordings,
      dateRecord,
      togglePanelRecording,
      Util,
      submitButtonProperties,
      wholeEggsMass
    }
  },
}
</script>
