<template>
  <div v-if="pullet">
    <div class="bg-white py-2 md:px-8 px-3">
      <!-- Pullets Profile-->
      <table class="mb-4 text-sm text-gray-500">
        <tr v-for="item in pullet" :key="item">
          <td class="px-2 py-1">{{ item['key'] }}</td>
          <td class="px-2 py-1">{{ item['value'] }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import Util from "../../util/MyUtil"
import {ref} from "@vue/runtime-core";

export default {

  name: "Info",

  setup() {

    let pullet = ref({});


    axios.get('/api/hen-houses/' + router.currentRoute.value.params.id).then((response)=>{
      if (response.status === 200) {
        const pulletActive = response.data.data['pullet_active'];
        if (response.data.data['pullet_active'] !== null) {
          pullet.value = [
            { key: 'Saldo', value:  Util.toRupiah(pulletActive['balance'])},
            { key: 'Supplier', value:  pulletActive['supplier']},
            { key: 'Tipe Pullet', value:  pulletActive['hen_type']['name']},
            { key: 'Tanggal Masuk', value:  Util.toLocaleDateString(pulletActive['date_in'])},
            { key: 'Usia Masuk', value:  pulletActive['start_age'] + ' Hari (' + Util.dayToWeek(pulletActive['start_age']) + ' Minggu)'},
            { key: 'Usia Sekarang', value:  pulletActive['current_age'] + ' Hari (' + Util.dayToWeek(pulletActive['current_age']) + ' Minggu)'},
            { key: 'Populasi Awal', value:  Util.numberWithDots(pulletActive['start_population']) + ' Ekor'},
            { key: 'Populasi Sekarang', value:  Util.numberWithDots(pulletActive['current_population']) + ' Ekor'},
            { key: 'Kematian', value:  Util.numberWithDots(pulletActive['mortality']) + ' Ekor'},
            { key: 'Harga Beli / Ekor', value:  Util.toRupiah(pulletActive['price'])},
          ]
        } else {
          pullet.value = false
        }
      }
    });

    return {
      pullet,
      Util,
    }

  }

}
</script>

<style scoped>

</style>
